import {compile, pathToRegexp} from 'path-to-regexp'
import has from 'lodash-es/has'

const cache = {}

export class Router {
    paths = {}

    get(name, ...args) {
        const path = this.paths[name]

        if (!path) {
            throw `Path ${path} not found`
        }

        if (!args.length) {
            return path
        }

        if (!has(cache, path)) {
            cache[path] = compile(path)
        }

        return cache[path](...args)
    }

    getAll() {
        return this.paths
    }

    add(name, path = name, routerCallback) {
        if (this.paths[name]) {
            throw new Error(`Route ${name} already defined`)
        }

        if (path[0] !== '/') {
            path = '/' + path
        }

        this.paths[name] = path

        if (routerCallback) {
            const subRouter = {
                add: (subname, subpath = subname, routerCallback) => {
                    if (subpath[0] !== '/') {
                        subpath = '/' + subpath
                    }

                    this.add(name + '.' + subname, path + subpath, routerCallback)
                    return subRouter
                },
            }

            routerCallback(subRouter)
        }

        return this
    }
}

const paths = new Router()

paths
    .add('/')
    .add('accounts', '/accounts/:scope?')
    .add('panels', '/panels/:scope?')
    .add('batches', '/batches', (router) => {
        router.add('runners', '/:id/runners')
    })
    .add('remoteInspections', '/remote-inspections/:scope?')
    .add('events', '/events/:scope?')
    .add('reports', '/reports/:scope?')
    .add('dashboard', '/dashboard')
    .add('panel', '/panel/:id', (router) => {
        router
            .add('devices', '/')
            .add('info')
            .add('state')
            .add('configuration', 'configuration', (router) => {
                router
                    .add('list', '')
                    .add('current')
                    .add('view', 'view/:configId')
                    .add('compare', 'compare/:configId')
            })
            .add('locations')
            .add('processes')
            .add('interactiveUsers', '/interactive-users')
            .add('installers')
            .add('reports')
            .add('log')
            .add('firmware')
            .add('remoteInspections', '/remote-inspections')
            .add('keypad')
            .add('events')
            .add('automation')
            .add('panel-test')
    })
    .add('firmware', '/firmware', (router) => {
        router.add('patchTagUpdate', '/patch-tag-update')
        router.add('upgrade', '/upgrade/:applianceId/:upgradePackageId').add('select')
    })
    .add('groups', '/groups')
    .add('group', '/groups/:id', (router) => {
        router
            .add('general', '/')
            .add('stations')
            .add('notifications')
            .add('customizations')
    })
    .add('users', '/users')
    .add('installers', 'installers/:scope?')
    .add('roles', '/roles')
    .add('basicConfigurations', '/basic-configurations')
    .add('basicConfiguration', '/basic-configurations/:id', (router) => {
        router.add('edit', '/').add('push')
    })
    .add('centralStations', '/central-stations')
    .add('centralStation', '/central-stations/:id', (router) => {
        router.add('general', '/').add('communication')
    })
    .add('processes')
    .add('actionLog', '/action-log')
    .add('settings', '/settings', (router) => {
        router
            .add('general', '/')
            .add('brokers')
            .add('receiver')
            .add('resolve')
            .add('interactive')
            .add('mfa')
            .add('eventRules', '/event-rules')
            .add('zoneTypes', '/zone-types')
            .add('notificationTypes', '/notification-types')
            .add('stateTypes', '/state-types')
            .add('eventTypes', '/event-types')
    })

export default function path(name, ...args) {
    return paths.get(name, ...args)
}

export function getPathKey(value, withParent = true) {
    const allPaths = paths.getAll()
    const pathKey = Object.keys(allPaths).find((key) => allPaths[key] === value)

    if (!withParent) {
        return pathKey.split('.').slice(-1)[0]
    }
    return pathKey
}

export function pathTrimEndSlash(name, ...args) {
    const path = paths.get(name, ...args)

    return path.endsWith('/') ? path.slice(0, -1) : path
}

export function pathParent(name, ...args) {
    return path(name, ...args) + '/*'
}

export function pathLastChild(name, ...args) {
    const fullPath = path(name, ...args)
    const sections = name.split('.')
    const parentSection = sections.splice(0, sections.length - 1).join('.')
    const parentPath = path(parentSection, ...args)

    return fullPath.replace(parentPath, '')
}

export function check(name, pathname) {
    return !!pathToRegexp(path(name)).exec(pathname)
}
