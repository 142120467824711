import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'

import {useActions} from 'modules/higherOrder/useActions'
import {startSession} from 'modules/devices/autolearn/actions'
import __ from 'utils/i18n'

export default function IdleButtons({session, onDismiss}) {
    const [onClick] = useActions([() => startSession(session)])

    return (
        <>
            <Button onClick={onDismiss} flat>
                {__('Dismiss')}
            </Button>
            <Button primary type="submit" onClick={onClick} Icon={IconRun}>
                {__('Start')}
            </Button>
        </>
    )
}

IdleButtons.propTypes = {
    session: PropTypes.object.isRequired,
    onDismiss: PropTypes.func.isRequired,
}
