import __ from '../utils/i18n'

export const NOT_ALLOWED_ERROR = 'NotAllowedError'

export const SELF_REGISTRATION_MODE_DISABLED = 0
export const SELF_REGISTRATION_MODE_AUTOMATIC_APPROVAL = 1
export const SELF_REGISTRATION_MODE_MANUAL_APPROVAL = 2

export const SELF_REGISTRATION_MODES = {
    [SELF_REGISTRATION_MODE_DISABLED]: __('Disabled'),
    [SELF_REGISTRATION_MODE_AUTOMATIC_APPROVAL]: __('Automatic Approval'),
    [SELF_REGISTRATION_MODE_MANUAL_APPROVAL]: __('Manual Approval'),
}
