import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import {__} from 'utils/i18n'
import Modal from 'ipmp-react-ui/Modal'

import imageChromeHelp from 'assets/images/notificationsHelp/chrome.png'
import imageFirefoxHelp from 'assets/images/notificationsHelp/firefox.png'

const decorate = (string, ...args) =>
    string.split('%s').reduce((acc, item, index) => {
        return acc.concat([
            item,
            <b key={index} className="primary">
                {args.shift()}
            </b>,
        ])
    }, [])

const FirefoxHelp = () => (
    <div>
        <ol className="simple-list">
            <li>{__('Click site settings icon in address line')}</li>
            <li>
                {decorate(
                    __('Next to %s, click %s sign to remove block'),
                    __('Receive Notifications'),
                    '⨉'
                )}
            </li>
            <li>{__('Try re-enable notifications in PowerManage')}</li>
            <li>{decorate(__('Choose %s when dialog appears'), __('Allow'))}</li>
        </ol>

        <img className="blockedNotificationsHelp-img" src={imageFirefoxHelp} alt="" />
    </div>
)

const ChromeHelp = () => (
    <div>
        <ol className="simple-list">
            <li>{__('Click site settings icon in address line')}</li>
            <li>
                {decorate(
                    __('Next to %s, select %s in drop down list'),
                    __('Notifications'),
                    __('Allow')
                )}
            </li>
        </ol>

        <img className="blockedNotificationsHelp-img" src={imageChromeHelp} alt="" />
    </div>
)

export default class BlockedNotificationsHelp extends Component {
    static propTypes = {
        hide: PropTypes.func.isRequired,
    }

    render() {
        const {hide} = this.props

        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

        return (
            <Modal onClose={hide}>
                <Card className="blockedNotificationsHelp">
                    <CardClose onClick={hide} />

                    <CardHeader>{__('Push notifications')}</CardHeader>

                    <CardContent>
                        <p className="blockedNotificationsHelp-text">
                            {__(
                                'Looks like your browser settings block PowerManage to show notifications.'
                            )}
                        </p>

                        <br />

                        <p className="hint hint--gray">
                            {__('To enable push notifications:')}
                        </p>

                        {isFirefox ? <FirefoxHelp /> : <ChromeHelp />}
                    </CardContent>

                    <CardActions>
                        <Button flat onClick={hide}>
                            {__('Close')}
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
        )
    }
}
