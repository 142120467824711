import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useSelection from 'hooks/useSelection'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import {requestCustomerApproval} from 'permissions/panel/info/page'
import {requestCustomerApprovalsBatch} from 'modules/panels/one/actions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import {selectIsInstallerRoleType} from 'modules/auth/sign/selectors'

export default function RequestCustomerApprovalButton() {
    const {selection} = useSelection()
    const request = useActions(requestCustomerApprovalsBatch)
    const {isPermitted} = usePermission(requestCustomerApproval)
    const isInstallerRoleType = useSelector(selectIsInstallerRoleType)

    if (selection.count() === 0 || !isPermitted || !isInstallerRoleType) {
        return null
    }

    const onClick = () => {
        request(selection.toJS())
    }

    return (
        <WithConfirmationButton
            onClick={onClick}
            label={__('Request customer approval')}
            title={__('Request customer approval')}
            message={__('Do you really want to request customer approval for panels?')}
        >
            {__('Request customer approval')}
        </WithConfirmationButton>
    )
}
