import React, {Component} from 'react'
import {compose} from 'redux'

import {download} from 'permissions/panel/configuration/actions'

import {PROCESS_TYPE_PMAXCONFIGUPLOAD} from 'constants/processTypes'
import {__} from 'utils/i18n'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import {withVisibility} from 'containers/withVisibility'
import withRunnerLoader from 'containers/withRunnerLoader'
import {withFeatureRejection} from './withFeature'
import {selectDownloadConfigutionFeature} from 'modules/features/store/selectors'
import Void from 'components/Void'
import usePermission from 'hooks/usePermission'

const ButtonVisibility = compose(
    withVisibility(),
    withFeatureRejection(selectDownloadConfigutionFeature, Void)
)(Button)

const DownloadButton = (props) => {
    const {isPermitted} = usePermission(download)

    return <ButtonVisibility {...props} isVisible={isPermitted} />
}

export function withConfigurationPreloader({isErrorCallback, buttonProps}) {
    return (WrappedComponent) => {
        class EmptyConfigurationHandler extends Component {
            componentDidUpdate() {
                const {outdated, fetch} = this.props
                outdated && fetch()
            }

            render() {
                const {refresh, panelId} = this.props

                if (isErrorCallback(this.props)) {
                    return (
                        <Error title={__('System Configuration not available yet')}>
                            <DownloadButton
                                panelId={panelId}
                                onClick={refresh}
                                {...buttonProps}
                                primary={false}
                                flat={true}
                            >
                                {__('Download now')}
                            </DownloadButton>
                        </Error>
                    )
                }

                return <WrappedComponent {...this.props} />
            }
        }

        return withRunnerLoader(
            ({type}) =>
                type === PROCESS_TYPE_PMAXCONFIGUPLOAD
                    ? __('Configuration is uploading')
                    : __('Configuration is downloading'),
            ({fetch}) => fetch(),
            buttonProps
        )(EmptyConfigurationHandler)
    }
}
