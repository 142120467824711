import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Spinner from 'ipmp-react-ui/Spinner'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {messageTitleByErrorType, USER_UNIT_ACCESS_DENIED} from 'constants/errorType'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'
import {requestCustomerApproval} from 'modules/panels/one/actions'
import {requestCustomerApproval as requestCustomerApprovalPermission} from 'permissions/panel/info/page'
import usePermission from 'hooks/usePermission'
import {selectPanelActions} from 'modules/features/store/selectors'
import {REQUEST_CUSTOMER_APPROVAL} from 'constants/panelAction'

const handlers = {
    [USER_UNIT_ACCESS_DENIED]: RequestCustomerApproval,
}

export default function withLoader(onMount, onUnmount) {
    return (WrappedComponent) =>
        function WithLoader(loaderProps) {
            useEffect(() => {
                onMount && onMount(loaderProps)

                return () => {
                    onUnmount && onUnmount(loaderProps)
                }
            }, [])

            const handleClick = () => {
                onMount && onMount(loaderProps)
            }

            const {isLoading, error, ...props} = loaderProps

            if (isLoading) {
                return <Spinner dataTestId="loading-spinner" />
            }

            if (error) {
                const ErrorHandler = handlers[error.type]

                return (
                    <Error error={error} title={messageTitleByErrorType(error.type)}>
                        {onMount && <Button onClick={handleClick}>{__('Retry')}</Button>}
                        {ErrorHandler && <ErrorHandler />}
                    </Error>
                )
            }

            return <WrappedComponent {...props} />
        }
}

function RequestCustomerApproval() {
    const panelId = useRouterPanelId()
    const request = useActions(requestCustomerApproval)
    const [disabled, setDisabled] = useState(false)
    const {isPermitted} = usePermission(requestCustomerApprovalPermission)

    const allowedPanelActions = useSelector((state) =>
        selectPanelActions(state, {panelId})
    )

    if (!isPermitted || !allowedPanelActions.includes(REQUEST_CUSTOMER_APPROVAL)) {
        return null
    }

    const onClick = () => {
        request(panelId)
        setDisabled(true)
    }

    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            className="request-customer-approval-btn"
        >
            {disabled
                ? __('Customer approval is requested')
                : __('Request customer approval')}
        </Button>
    )
}
