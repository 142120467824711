import React from 'react'
import classnames from 'classnames'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import useBilling from 'hooks/pages/useBilling'
import PanelBillingPlanBadge from './PanelBillingPlanBadge'
import {withPermissionVisibility} from 'containers/withPermission'
import {activate as activatePermission} from 'permissions/panel/billing/actions'

const ButtonActivate = withPermissionVisibility(activatePermission)(Button)

const buttonName = (active, requested) => {
    if (active) {
        if (requested) {
            return __('Current plan')
        }

        return __('Reactivate plan')
    }

    return __('Choose plan')
}

export default function PanelBillingPlan({
    id,
    panelId,
    name,
    description,
    active,
    requested,
    currentCountry,
}) {
    const {showModal, reactivatePlan} = useBilling(panelId)

    const onClick = () => {
        if (active && !requested && currentCountry) {
            return reactivatePlan({countryId: currentCountry.id, planId: id})
        }

        return showModal(id)
    }

    return (
        <div
            className={classnames('panelBillingPlan', {
                'panelBillingPlan--active': active && requested,
                'panelBillingPlan--requested': !active && requested,
            })}
        >
            <div className="panelBillingPlan__info">
                <PanelBillingPlanBadge name={name} id={id} />
                <div className="panelBillingPlan__choose">
                    <ButtonActivate
                        primary
                        onClick={onClick}
                        disabled={active && requested}
                        className="panelBillingPlan__choose-button"
                    >
                        {buttonName(active, requested)}
                    </ButtonActivate>
                </div>
            </div>
            <p className="panelBillingPlan__description">{description}</p>
        </div>
    )
}
