import React from 'react'
import {useSelector} from 'react-redux'

import {enroll} from 'permissions/panel/devices/actions'

import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {selectAddDeviceFeature} from 'modules/features/store/selectors'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useDevices from 'hooks/pages/useDevices'

import AddDevicesMenu from './AddDevicesMenu'
import AddCameraButton from './AddCameraButton'

export default function AddDeviceButton() {
    const {isPermitted} = usePermission(enroll)
    const {panelId, ...devicesProps} = useDevices()

    const {isAvailable} = useSelector((state) =>
        selectAddDeviceFeature(state, {panelId: panelId})
    )

    if (isAvailable) {
        return isPermitted ? (
            <>
                <Button
                    {...devicesProps}
                    Icon={PlusIcon}
                    label={__('Add device')}
                    onClick={devicesProps.showAddDevice}
                    className="devices-add-device-button"
                    primary
                />
                <AddDevicesMenu />
            </>
        ) : (
            <AddCameraButton />
        )
    }
}
