import {categoryByDeviceType} from 'constants/deviceCategory'
import sortBy from 'lodash-es/sortBy'
import _has from 'lodash-es/has'

import {mapProcess} from 'api/processes'
import mapper from 'utils/dataMapper'

import {SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE} from 'constants/severityType'
import {DEVICE_TYPE_CONTROL_PANEL, DEVICE_TYPE_GSM} from 'constants/deviceType'
import {isDeviceOpened} from 'constants/troubleType'

import {
    PANEL_TYPE_PWR_SRS_NEO_SMARTCOMM_PANEL,
    PANEL_TYPE_PWR_SRS_SMARTCOMM_PANEL,
    PANEL_TYPE_SMART_COMMUNICATOR,
    PANEL_TYPE_SMART_COMMUNICATOR_WITH_DSC,
} from 'constants/panelType'

const mapPanelInfo = mapper(({serial, model, firmware, extended_info}) => ({
    serial: serial,
    info: {
        model: model,
    },
    extendedInfo: extended_info,
    firmware: firmware,
}))

const mapPanelState = mapper((data) => ({
    modules: {
        bba: data.bba,
        bba_over_gprs: data.bba_over_gprs,
        gprs: data.gprs,
        wifi: data.wifi,
    },
}))

const mapPanelRelatedDevices = (devices) => {
    const controlPanel =
        devices.find(
            ({device_type: deviceType}) => deviceType === DEVICE_TYPE_CONTROL_PANEL
        ) || {}

    const smartCommPanel =
        devices.find(
            ({subtype: subtype}) =>
                subtype === PANEL_TYPE_SMART_COMMUNICATOR ||
                subtype === PANEL_TYPE_SMART_COMMUNICATOR_WITH_DSC ||
                subtype === PANEL_TYPE_PWR_SRS_SMARTCOMM_PANEL ||
                subtype === PANEL_TYPE_PWR_SRS_NEO_SMARTCOMM_PANEL
        ) || false

    return {
        smartCommPanel: smartCommPanel,
        warnings: [...mapWarnings(controlPanel.warnings)],
    }
}

export const mapDevice = mapper((data) => {
    const zoneType =
        data.zone_type && data.zone_type.toUpperCase && data.zone_type.toUpperCase()
    const deviceType =
        data.device_type &&
        data.device_type.toUpperCase &&
        data.device_type.toUpperCase().replace(' ', '_')
    const traits = _has(data, 'traits') ? data.traits : {}
    return {
        id: parseInt(data.id),
        name: data.name,
        zone: data.device_number,
        deviceId: data.enrollment_id,
        deviceType: data.device_type,
        deviceTypeId: parseInt(data.device_type_id),
        category: categoryByDeviceType(deviceType, data.subtype),
        zoneType,
        subtype: data.subtype,
        preenroll: data.preenroll,
        enrolling: data.enrolling,
        removing: data.removing,
        partitions: data.partitions,
        traits,
        warnings: mapWarnings(data.warnings, zoneType),
        open: data.warnings ? isDeviceOpened(data.warnings) : false,
        hasTroubles: !!(data.warnings && data.warnings.length),
        isRemovable: data.removable,
        isRenameable: data.renamable,
    }
})

const mapWarning = mapper((warning, zoneType) => ({
    comment: warning.comment,
    inMemory: warning.in_memory,
    severity: warning.severity,
    type: warning.type,
    zoneType,
}))

const warningsOrder = [SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE]

const mapWarnings = (warnings, zoneType) => {
    if (!warnings || warnings.length === 0) {
        return []
    }

    return sortBy(
        warnings.map((warning) => mapWarning(warning, zoneType)),
        (warning) => warningsOrder.indexOf(warning.severity)
    )
}

const getGsm = (devices) => {
    const filtered = devices.filter((device) => device.device_type === DEVICE_TYPE_GSM)

    return filtered.length ? filtered[0] : null
}

export const map = ({
    panelInfo,
    panelState,
    devices,
    rssiRefreshProcess,
    gsmRefreshProcess,
    startClearDeviceRunner,
    stopClearDeviceRunner,
    panelRebootProcess,
    panelEngineeringResetProcess,
    sysLogFetchOutsidePmProcess,
    panelRemindMasterUserCodeProcess,
    rssi,
}) => ({
    panel: {
        ...mapPanelInfo(panelInfo),
        ...mapPanelState(panelState),
        ...mapPanelRelatedDevices(devices),
        gsm: getGsm(devices),
    },
    devices: devices.map(mapDevice),
    rssiRefreshProcess: rssiRefreshProcess && mapProcess(rssiRefreshProcess),
    GSMRefreshProcess: gsmRefreshProcess && mapProcess(gsmRefreshProcess),
    startClearDeviceRunner: startClearDeviceRunner && mapProcess(startClearDeviceRunner),
    stopClearDeviceRunner: stopClearDeviceRunner && mapProcess(stopClearDeviceRunner),
    panelRebootProcess: panelRebootProcess && mapProcess(panelRebootProcess),
    panelEngineeringResetProcess:
        panelEngineeringResetProcess && mapProcess(panelEngineeringResetProcess),
    sysLogFetchOutsidePmProcess:
        sysLogFetchOutsidePmProcess && mapProcess(sysLogFetchOutsidePmProcess),
    panelRemindMasterUserCodeProcess:
        panelRemindMasterUserCodeProcess && mapProcess(panelRemindMasterUserCodeProcess),
    rssi: rssi,
})
