import React, {useCallback, useEffect, useState} from 'react'

import Page from 'ipmp-react-ui/Page'

import {useActions} from 'modules/higherOrder/useActions'
import CentralStationsTable from './CentralStationsTable'
import CentralStationsGridBar from './CentralStationsGridBar'
import {startPoll, stopPoll} from 'modules/centralStations/list/actions'

export default function CentralStationsPage() {
    const {startPoll: startPollAction, stopPoll: stopPollAction} = useActions({
        startPoll,
        stopPoll,
    })
    const [hasData, setHasData] = useState(false)

    useEffect(() => {
        startPollAction()

        return stopPollAction
    }, [])

    const handleDataAvailability = useCallback((isAvailable) => {
        setHasData(isAvailable)
    }, [])

    return (
        <Page
            Bar={hasData ? CentralStationsGridBar : null}
            Content={() => (
                <CentralStationsTable onDataAvailable={handleDataAvailability} />
            )}
        />
    )
}
