import has from 'lodash-es/has'
import isEmpty from 'lodash-es/isEmpty'

import * as infoApi from 'api/panel/info'
import * as testsApi from 'api/panel/panelTest'

import * as api from 'api/panels'
import * as processType from 'constants/processTypes'
import {activateMasterGUIPanel, activatePanel} from 'modules/forms/handlers'
import forgetPanels from 'modules/panels/one/watchers/forgetPanels'
import clearFeatures from './watchers/clearFeatures'
import ensureProcess, {ensureProcesses} from 'modules/processes/manager/ensureProcess'
import generateProcess from 'modules/processes/manager/generateProcess'
import generateBatch, {
    generateBatchForOneProcess,
    generateBatchForPanel,
} from 'modules/batches/manager/generateBatch'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import {snackShow} from 'modules/snacks'
import {push} from 'redux-first-history'
import {all, call, put, select, takeEvery, take} from 'redux-saga/effects'

import path from 'utils/path'

import {
    setActivationFailed,
    setActivationStatus,
    setActivationSuccess,
    update,
} from '../store/actions'
import {init} from '../list/actions'
import * as actions from './actions'
import pollers from './pollers'
import {fetchTestsHistory, setTestStatusId} from './actions'
import {PROCESS_TYPE_REQUEST_CUSTOMER_APPROVAL} from 'constants/processTypes'
import __ from 'utils/i18n'

export default function* () {
    yield all([
        takeEvery(
            [actions.setUserAppState, actions.setConfigurationAppState],
            watchAppState
        ),
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.refreshState, watchRefreshState),
        takeEvery(actions.remove, watchRemove),
        takeEvery(activatePanel.SUCCESS, watchActivatePanel),
        takeEveryProcessComplete(
            processType.PROCESS_TYPE_NEOACTIVATION,
            watchNeoActivation
        ),
        takeEvery(activateMasterGUIPanel.SUCCESS, watchUserActivation),
        takeEveryProcessComplete(
            processType.PROCESS_TYPE_PMAXSTATEGET,
            watchRefreshStateComplete
        ),
        takeEvery(actions.fetchUsers, watchFetchUsers),
        takeEvery(actions.fetchTestsHistory, watchFetchTests),
        takeEvery(actions.runPanelTest, watchRunTests),
        takeEvery(actions.requestCustomerApproval, watchRequestCustomerApproval),
        takeEvery(
            actions.requestCustomerApprovalsBatch,
            watchRequestCustomerApprovalsBatch
        ),
        pollers(),
    ])
}

function* watchFetch({payload}) {
    try {
        const {panelId} = payload
        const activatingStarted = yield select(({panels}) => panels.store.byIds[panelId])
            .activatingStarted
        const [panel, online] = yield all([
            infoApi.retrieveData(panelId),
            infoApi.isOnline(panelId),
        ])

        if (!panel.isActivated) {
            const {isActivating} = yield call(infoApi.isPanelActivating, panelId)
            panel.isActivating = activatingStarted ? activatingStarted : isActivating
        }

        const data = {
            id: panelId,
            ...panel,
            ...online,
        }

        if (data.refreshProcess) {
            data.refreshProcess = yield ensureProcess(data.refreshProcess)
        }

        yield put(update(data))
        yield put(actions.setLoading(false))
    } catch (error) {
        yield put(actions.setError(error))
    }
}

function* watchRequestCustomerApproval({payload: {panelId}}) {
    const {batchId, removeBatch} = yield generateBatchForPanel(
        PROCESS_TYPE_REQUEST_CUSTOMER_APPROVAL,
        panelId
    )

    const {execute} = yield generateProcess(
        PROCESS_TYPE_REQUEST_CUSTOMER_APPROVAL,
        panelId
    )

    try {
        yield execute(infoApi.triggerRequestCustomerApproval, panelId, batchId)
    } catch (error) {
        yield put(snackShow(error.message))

        yield removeBatch()
    }
}

function* watchRequestCustomerApprovalsBatch({payload: {panelIds}}) {
    const {batchId, removeBatch} = yield generateBatch(
        PROCESS_TYPE_REQUEST_CUSTOMER_APPROVAL,
        panelIds
    )

    try {
        const {processes} = yield infoApi.triggerRequestCustomerApprovals(
            panelIds,
            batchId
        )

        yield ensureProcesses(processes)
        yield put(snackShow(__('Customer approval requested')))
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchUserActivation({payload}) {
    const {result: process} = payload

    yield watchFetch({payload: process})
}

function* watchFetchUsers({payload: {panelId}}) {
    try {
        const userInfo = yield call(infoApi.getUsers, panelId)

        yield put(
            update({
                id: panelId,
                ...userInfo,
            })
        )
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchRefreshState({payload: {panelId}}) {
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        processType.PROCESS_TYPE_PMAXSTATEGET,
        panelId
    )
    const {execute, process} = yield generateProcess(
        processType.PROCESS_TYPE_PMAXSTATEGET,
        panelId
    )

    yield put(
        update({
            id: panelId,
            refreshProcess: process,
        })
    )

    try {
        yield execute(infoApi.refreshState, panelId, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(
            update({
                id: panelId,
                refreshProcess: null,
            })
        )
        yield removeBatch()
    }
}

function* watchRefreshStateComplete(process) {
    const {isFailed, panelId, errorMessage} = process

    if (isFailed) {
        yield put(snackShow(errorMessage))
    }

    yield put(
        update({
            id: panelId,
            refreshProcess: null,
        })
    )
}

function* watchRemove({payload: ids}) {
    if (ids.length === 0) {
        return
    }

    const {batchId, removeBatch} = yield generateBatch(
        processType.PROCESS_TYPE_PMAXRESETRECEIVERIP,
        ids
    )

    try {
        yield call(api.remove, ids, batchId)

        // If we are on the deleted panel page, we should wait until the panel component is unmounted,
        // otherwise, we can continue immediately
        const {pathname} = yield select((state) => state.router.location)
        if (isCurrentPageInIds(pathname, ids)) {
            // Redirect to the panel list
            yield put(push(path('panels', null)))

            // Wait until unmount of the panel, so we can delete related entities
            yield take(init)
        }

        yield forgetPanels(ids)
        yield clearFeatures(ids)
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchAppState({payload}) {
    const {ids, ...data} = payload
    const state = yield select(({panels}) => panels.store.byIds)
    yield put(update(ids.map((id) => ({id, ...data}))))

    try {
        const promises = []

        if (has(data, 'userApp')) {
            promises.push(call(api.processUserApp, ids, data.userApp))
        }

        if (has(data, 'configuratorApp')) {
            promises.push(call(api.processConfiguratorApp, ids, data.configuratorApp))
        }

        yield all(promises)
    } catch (error) {
        // revert to old values
        yield put(update(createRevert(state, ids, data)))
        yield put(snackShow(error.message))
    }
}

function* watchActivatePanel({payload}) {
    const {result: process} = payload
    const {panelId} = process

    yield ensureProcess(process)

    yield put(setActivationStatus({panelId, isActivating: true}))
}

function* watchNeoActivation(process) {
    const {isFailed, isSuccessful, panelId, errorMessage} = process

    if (isFailed) {
        yield put(setActivationFailed({panelId, error: errorMessage}))
    }
    if (isSuccessful) {
        yield put(setActivationSuccess({panelId}))
    }
}

function isCurrentPageInIds(pathname, ids) {
    const panelRegexp = /^\/panel\/([\d]+).*$/
    const match = pathname.match(panelRegexp)
    return match && ids.includes(parseInt(match[1]))
}

const createRevert = (state, ids, data) =>
    ids.map((id) =>
        Object.keys(data).reduce(
            (acc, key) => ({
                ...acc,
                [key]: state[id][key],
            }),
            {id}
        )
    )

function* watchFetchTests({payload: {panelId}}) {
    try {
        const testsData = yield call(testsApi.getPanelTestsHistory, panelId)
        yield put(actions.updateTestsHistory({panelId, testsData}))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchRunTests({payload: {panelId, tests}}) {
    try {
        const processes = yield call(testsApi.runPanelTests, panelId, tests)
        const result =
            processes &&
            processes.map((process) => {
                const matchingTest = tests.find((test) => {
                    const processType = process.type.toLowerCase()
                    const testName = test.testName.toLowerCase()

                    return testName === processType
                })

                if (matchingTest) {
                    return {
                        ...process,
                        testType: matchingTest?.testType,
                    }
                }

                return process
            })

        yield put(setTestStatusId(panelId, result))

        if (!processes || isEmpty(processes)) {
            yield put(fetchTestsHistory(panelId))
        }
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(fetchTestsHistory(panelId))
    }
}
