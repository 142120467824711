import React from 'react'

import {create} from 'permissions/centralStations/actions'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/pages/useCentralStations'

const ButtonVisibility = withVisibility()(Button)

export default function CreateCentralStation() {
    const {showCentralStationCreateModal} = useCentralStations()
    const {isPermitted} = usePermission(create)

    return (
        <ButtonVisibility
            isVisible={isPermitted}
            onClick={showCentralStationCreateModal}
            Icon={IconPlus}
            label={__('Add Central Station')}
            primary
        />
    )
}
