import {createSelector} from 'reselect'
import isNil from 'lodash-es/isNil'
import get from 'lodash-es/get'
import {
    VIRTUAL_KEYPAD,
    FIRMWARE,
    EVENTS,
    REMOTE_INSPECTIONS,
    LOGS,
    REPORTS,
    PROCESSES,
    LOCATIONS,
    CONFIGURATION,
    CONFIGURATION_BASIC,
    CONFIGURATION_DOWNLOADABLE,
    CONFIGURATION_UPLOADABLE,
    STATE,
    STATE_CAN_GET,
    STATE_CAN_SET,
    STATE_CAN_REFRESH,
    DEVICES,
    WALKTEST,
    ENROLLABLE,
    USER,
    CONFIGURATOR,
    APPS,
    DISCOVERY,
    FORCE_DISCOVERY,
    RSSI,
    RSSI_DEVICES,
    RSSI_GMS,
    ACTIVATION,
    FAULTS,
    TYPE_2_ENCRYPTION,
    TYPE_2_ENCRYPTION_BBA,
    TYPE_2_ENCRYPTION_GPRS,
    DIAGNOSTIC,
    VOD,
    ASSIGNABLE,
    INSTALLER,
    INSTALLER_CODE_LENGTHS,
    USER_FEATURE_EDITABLE,
    USER_FEATURE_PARTITIONS,
    IS_LABELS_EDITABLE,
    USER_FEATURE_VALIDATION_RULES,
    PANEL_TEST,
} from 'constants/features'
import {err} from 'utils/log'
import {devicesToEnrollExists} from 'constants/deviceSubtype'
import {MODULE_TYPE_ANY, MODULE_TYPE_GPRS, MODULE_TYPE_WIFI} from 'constants/moduleTypes'

export const selectPanelFeatures = createSelector(
    (state, {panelId}) => state.features.store.byIds[panelId],
    (state) => state.features.store.isLoading,
    (features, isFeatureLoading) => ({features, isFeatureLoading})
)

const noPaneIdErrorMessage = (key, subKey) =>
    `No panel id passed in selector for key "${key}"${
        subKey ? ` and subkey "${subKey}"` : ''
    }`

const createFeatureSelector = ({
    key,
    subKey = 'isEnabled',
    isAvailableKey = 'isAvailable',
}) =>
    createSelector(
        (state, {panelId}) => {
            if (isNil(panelId)) {
                throw new Error(noPaneIdErrorMessage(key, subKey))
            }

            const panelFeatures = state.features.store.byIds[panelId]

            if (!panelFeatures) {
                return false
            }

            return get(state.features.store.byIds, [panelId, key, subKey])
        },
        (isAvailable) => ({[isAvailableKey]: isAvailable})
    )

export const selectKeypadFeature = createFeatureSelector({key: VIRTUAL_KEYPAD})
export const selectFirmwareFeature = createFeatureSelector({key: FIRMWARE})
export const selectPanelTestFeature = createFeatureSelector({key: PANEL_TEST})
export const selectEventsFeature = createFeatureSelector({key: EVENTS})
export const selectRemoteInspectionsFeature = createFeatureSelector({
    key: REMOTE_INSPECTIONS,
})
export const selectLogsFeature = createFeatureSelector({key: LOGS})
export const selectReportsFeature = createFeatureSelector({key: REPORTS})
export const selectReportsContentOptionsFeature = createFeatureSelector({
    key: REPORTS,
    subKey: 'contentOptions',
    isAvailableKey: 'contentOptions',
})
export const selectProcessesFeature = createFeatureSelector({key: PROCESSES})
export const selectLocationsFeature = createFeatureSelector({key: LOCATIONS})
export const selectConfigutionFeature = createFeatureSelector({key: CONFIGURATION})
export const selectDownloadConfigutionFeature = createFeatureSelector({
    key: CONFIGURATION,
    subKey: CONFIGURATION_DOWNLOADABLE,
})
export const selectMakeBasicConfigurationFeature = createFeatureSelector({
    key: CONFIGURATION,
    subKey: CONFIGURATION_BASIC,
})
export const selectUploadConfigurationFeature = createFeatureSelector({
    key: CONFIGURATION,
    subKey: CONFIGURATION_UPLOADABLE,
})
export const selectStateFeature = createFeatureSelector({key: STATE})
export const selectStateCanGet = createFeatureSelector({
    key: STATE,
    subKey: STATE_CAN_GET,
    isAvailableKey: 'isCanGetAvailable',
})
export const selectStateCanSet = createFeatureSelector({
    key: STATE,
    subKey: STATE_CAN_SET,
    isAvailableKey: 'isCanSetAvailable',
})
export const selectDevicesFeature = createFeatureSelector({key: DEVICES})
export const selectWalktestFeature = createFeatureSelector({key: WALKTEST})
// export const selectAddDeviceFeature = createFeatureSelector({
//     key: DEVICES,
//     subKey: ENROLLABLE,
// })
export const selectAddDeviceFeature = createSelector(
    (state, {panelId}) => {
        if (isNil(panelId)) {
            throw new Error(noPaneIdErrorMessage(DEVICES))
        }

        const panelFeatures = state.features.store.byIds[panelId]

        if (!panelFeatures) {
            err(`No panel features for "${panelId}"`)
            return false
        }

        return (
            panelFeatures[DEVICES][ENROLLABLE] &&
            devicesToEnrollExists(get(panelFeatures, [DEVICES, 'devices'], []))
        )
    },
    (isAvailable) => ({isAvailable})
)
export const selectAppUser = createFeatureSelector({key: APPS, subKey: USER})
export const selectAppConfigurator = createFeatureSelector({
    key: APPS,
    subKey: CONFIGURATOR,
})
export const selectDiscovery = createFeatureSelector({key: DISCOVERY})
export const selectForceDiscovery = createFeatureSelector({
    key: DISCOVERY,
    subKey: FORCE_DISCOVERY,
})
export const selectRSSIDevices = createFeatureSelector({
    key: RSSI,
    subKey: RSSI_DEVICES,
})
export const selectRSSIGsm = createFeatureSelector({
    key: RSSI,
    subKey: RSSI_GMS,
})
export const selectActivation = createFeatureSelector({key: ACTIVATION})

export const selectRefreshState = createFeatureSelector({
    key: STATE,
    subKey: STATE_CAN_REFRESH,
})
export const selectFaults = createFeatureSelector({
    key: FAULTS,
})
export const selectEncryptionBBA = createFeatureSelector({
    key: TYPE_2_ENCRYPTION,
    subKey: TYPE_2_ENCRYPTION_BBA,
})
export const selectEncryptionGPRS = createFeatureSelector({
    key: TYPE_2_ENCRYPTION,
    subKey: TYPE_2_ENCRYPTION_GPRS,
})
export const selectDiagnosticFeature = createFeatureSelector({
    key: DIAGNOSTIC,
})

export const selectVod = createSelector(
    (state, {panelId}) => {
        if (isNil(panelId)) {
            throw new Error('Not found vod feature')
        }
        const panelFeatures = state.features.store.byIds[panelId]
        if (!panelFeatures) {
            throw new Error('Not found vod feature')
        }
        return get(state.features.store.byIds, [panelId, VOD])
    },
    (vod) => ({feature: vod})
)

export const selectAssignableFeature = createFeatureSelector({key: ASSIGNABLE})

export const selectModuleEncryptionIsEnabled = createSelector(
    (state, {type, panelId}) => {
        const module = state.features.store.byIds[panelId]?.encryption[type]
        return module && (module?.canEnable || module?.canDisable)
    },
    (result) => {
        return result
    }
)

export const selectModuleEncrypted = createSelector(
    ({type, modules}) => ({type, modules}),
    ({type, modules}) => {
        if (type === MODULE_TYPE_ANY) {
            return (
                modules &&
                (modules[MODULE_TYPE_GPRS]?.encrypted ||
                    modules[MODULE_TYPE_WIFI]?.encrypted)
            )
        }
        return modules && modules[type]?.encrypted
    }
)

export const selectInstallerCodeLengthsFeature = createSelector(
    (state, {panelId}) => {
        if (isNil(panelId)) {
            throw new Error('Not found installer feature')
        }
        const panelFeatures = state.features.store.byIds[panelId]
        if (!panelFeatures) {
            throw new Error('Not found installer feature')
        }

        return get(state.features.store.byIds, [
            panelId,
            INSTALLER,
            INSTALLER_CODE_LENGTHS,
        ])
    },
    (installer) => ({code_lengths: installer})
)

export const selectUserEditableFeature = createSelector(
    createFeatureSelector({
        key: USER,
        subKey: USER_FEATURE_EDITABLE,
        isAvailableKey: 'list',
    }),
    (state, {item}) => item,
    ({list}, item) => list?.includes(item)
)
// TODO select feature
export const selectHadFeature = () => ({isAvailable: true})

export const selectFeaturesInstaller = createSelector(
    (features, panelId) => features.store.byIds[panelId],
    (result) => result.installer
)

export const selectPartitionLabelsEditable = createFeatureSelector({
    key: USER_FEATURE_PARTITIONS,
    subKey: IS_LABELS_EDITABLE,
})

export const selectPartitionsEnabled = createFeatureSelector({
    key: USER_FEATURE_PARTITIONS,
})

export const selectDevicesLabelValidationRules = createSelector(
    (features, panelId) => features.store.byIds[panelId],
    (featureSet) => featureSet[USER_FEATURE_VALIDATION_RULES]?.device_label_rules
)

export const selectConfigurationValidationRules = createSelector(
    (features, panelId) => features.store.byIds[panelId],
    (featureSet) => featureSet[USER_FEATURE_VALIDATION_RULES]?.rules
)

export const selectClearingProtocols = createSelector(
    (state, {panelId}) => state.features.store.byIds[panelId].devices.clearing,
    (result) => result
)

export const selectAvailableAutolearn = createSelector(
    (state, {panelId}) => state.features.store.byIds[panelId].devices.autolearn,
    (result) => result
)

export const selectPanelActions = createSelector(
    (state, {panelId}) =>
        state.features.store.byIds[panelId]?.panel_actions?.actions || [],
    (result) => result
)

export const selectLogTypes = createSelector(
    (state, {panelId}) => state.features.store.byIds[panelId].logs.types,
    (result) => result
)
