import React from 'react'
import __ from 'utils/i18n'
import {useSelector} from 'react-redux'

import {selectFirmwareList} from 'modules/firmware/list/selectors'
import {setQuery, clearFilters} from 'modules/firmware/list/actions'
import {useActions} from 'modules/higherOrder/useActions'
import Search from 'components/Search/Search'

export default function FirmwareSearch({className}) {
    const {query} = useSelector(selectFirmwareList)

    const {onQuery, onClear} = useActions({
        onQuery: setQuery,
        onClear: clearFilters,
    })

    return (
        <Search
            query={query}
            onQuery={onQuery}
            filters={[]}
            selected={[]}
            onClear={onClear}
            className={className}
            placeholder={__('Search Firmware')}
            hideAutoCompleteAfterQuery
            saveSearchStringAfterQuery={false}
        />
    )
}
