import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {
    setAlarmsEnabled,
    setAlarmsSoundEnabled,
    setAlarmsNotificationsEnabled,
    setAlarmSound,
} from 'modules/settings/actions'

import {CardActions, CardContent, CardHeader, CardClose} from 'ipmp-react-ui/Card'
import Button from 'ipmp-react-ui/Button'
import Togglebox from 'ipmp-react-ui/ToggleBox'

import {__} from 'utils/i18n'

import AlarmsSounds from 'components/Alarms/AlarmsSounds'
import soundMap from 'audio/sounds'
import {severityForChooseSound} from 'constants/eventSeverity'
import {DEFAULT_ALARM_SOUNDS} from 'modules/settings/reducer'

class CommonSettings extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        isAlarmsEnabled: PropTypes.bool,
        isAlarmSoundEnabled: PropTypes.bool,
        setAlarmsEnabled: PropTypes.func.isRequired,
        setAlarmsSoundEnabled: PropTypes.func.isRequired,
        setAlarmsNotificationsEnabled: PropTypes.func.isRequired,
    }

    handleEnableSupervisionChange = (e) => {
        const {setAlarmsEnabled} = this.props
        setAlarmsEnabled(e.target.checked)
    }

    handleEnableSoundChange = (e) => {
        const {setAlarmsSoundEnabled} = this.props
        setAlarmsSoundEnabled(e.target.checked)
    }

    handleEnableNotificationsChange = (e) => {
        const {setAlarmsNotificationsEnabled} = this.props
        setAlarmsNotificationsEnabled(e.target.checked)
    }

    handleSetAlarmSound = ({alarmType, soundKey}) => {
        this.props.setAlarmSound({soundKey, eventSeverityKey: alarmType})
    }

    handleClose = () => {
        const {onClose} = this.props

        onClose()
    }

    render() {
        const {
            isAlarmsEnabled,
            isAlarmSoundEnabled,
            isAlarmNotificationsEnabled,
            alarmTypeToSound,
        } = this.props

        return (
            <div className="editSettings-layout alarmsSetting">
                <CardClose onClick={this.handleClose} />
                <CardHeader>{__('Events Supervision settings')}</CardHeader>
                <CardContent>
                    <AlarmsSounds
                        sounds={soundMap}
                        alarmTypes={severityForChooseSound}
                        alarmTypeToSound={alarmTypeToSound}
                        setAlarmSound={this.handleSetAlarmSound}
                    />

                    <h3 className="form-section-header">{__('Events supervision')}</h3>

                    <Togglebox
                        label={__('Enable events supervision')}
                        onChange={this.handleEnableSupervisionChange}
                        checked={isAlarmsEnabled}
                    />

                    <br />
                    <p className={isAlarmsEnabled ? '' : 'empty'}>
                        {__('When event occurred:')}
                    </p>

                    <Togglebox
                        label={__('Show notification')}
                        onChange={this.handleEnableNotificationsChange}
                        checked={isAlarmNotificationsEnabled}
                        disabled={!isAlarmsEnabled}
                    />

                    <Togglebox
                        label={__('Play sound')}
                        onChange={this.handleEnableSoundChange}
                        checked={isAlarmSoundEnabled}
                        disabled={!isAlarmsEnabled}
                    />
                </CardContent>
                <CardActions>
                    <Button flat onClick={this.handleClose}>
                        {__('Close')}
                    </Button>
                </CardActions>
            </div>
        )
    }
}

export default compose(
    connect(
        ({settings}) => {
            const {alarms} = settings

            return {
                isAlarmsEnabled: alarms.isEnabled,
                isAlarmSoundEnabled: alarms.isSoundEnabled,
                isAlarmNotificationsEnabled: alarms.isNotificationsEnabled,
                alarmTypeToSound: alarms.sounds ?? DEFAULT_ALARM_SOUNDS,
            }
        },
        (dispatch) =>
            bindActionCreators(
                {
                    setAlarmsEnabled,
                    setAlarmsSoundEnabled,
                    setAlarmsNotificationsEnabled,
                    setAlarmSound,
                },
                dispatch
            )
    )
)(CommonSettings)
