import React from 'react'

import Input from 'ipmp-react-ui/Input'
import Select from 'ipmp-react-ui/Select'
import Autocomplete from 'ipmp-react-ui/Autocomplete'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {convertInstaller} from 'modules/forms/handlers'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchCountries} from 'modules/countries/actions'
import {fetch as fetchRoles} from 'modules/users/assignableRoles/actions'
import {DEALER_TYPE} from 'constants/role'

export const rules = {
    name: {
        presence: true,
        length: {maximum: 255},
    },
    phone: {
        presence: true,
        phone: true,
    },
    coy_id: {
        presence: true,
    },
    roe_id: {
        presence: true,
    },
}

const RolesSelect = withSelectLoader(
    fetchRoles,
    (state) => state.users.assignableRoles.isLoading,
    ({users}) =>
        Object.values(users.assignableRoles.byIds)
            .filter((role) => role.type !== DEALER_TYPE)
            .map(({id, name}) => ({
                value: id,
                label: name,
            }))
)(Select)

const CountrySelect = withSelectLoader(
    fetchCountries,
    (state) => state.countries.isLoading,
    ({countries}) =>
        Object.values(countries.byIds).map(({id, name}) => ({value: id, label: name}))
)(Autocomplete)

export default function ConvertInstaller({hide, installer}) {
    const {handle, isLoading, form} = useForm(convertInstaller)

    const onSubmit = (data) => handle({...data, installer_id: installer.id})

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={onSubmit}
            isLoading={isLoading}
            submitLabel={__('Convert')}
            header={__('Installer conversion')}
        >
            <Input
                autoFocus
                label={__('Full Name')}
                name="name"
                defaultValue={installer.name}
            />
            <Input
                label={__('Email Address')}
                name="email"
                disabled
                defaultValue={installer.email}
            />
            <Input label={__('Phone')} name="phone" defaultValue={installer.telephone} />
            <CountrySelect label={__('Country')} name="coy_id" />
            <RolesSelect label={__('Role')} name="roe_id" />
        </ModalCardForm>
    )
}
