import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import __ from 'utils/i18n'

import {withPermissionVisibility} from 'containers/withPermission'
import {useActions} from 'modules/higherOrder/useActions'
import {remove} from 'modules/interactiveUsers/list/actions'
import {remove as removePermission} from 'permissions/interactiveUsers/actions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'

const RemoveCustomerButton = ({user: {id, email}, onClose}) => {
    const {onClick} = useActions({onClick: remove})

    return (
        <WithConfirmationButton
            Icon={IconRemove}
            flat
            onClick={() => {
                onClick([id])
                onClose && onClose()
            }}
            message={__(
                'Do you really want to remove the account %s from the server?',
                email
            )}
            title={__('Remove Account')}
            label={__('Remove Account')}
        />
    )
}

RemoveCustomerButton.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func,
}

export default withPermissionVisibility(removePermission)(RemoveCustomerButton)
