import React from 'react'
import PropTypes from 'prop-types'

import {InfoCard} from 'ipmp-react-ui/InfoCards'

import Error from 'ipmp-react-ui/Error'

export default function EmptyPageCard({title, message, Button}) {
    return (
        <InfoCard cols={1} title={title}>
            <Error title={message}>{Button}</Error>
        </InfoCard>
    )
}

EmptyPageCard.propTypes = {
    title: PropTypes.string,
    Icon: PropTypes.node,
    message: PropTypes.string,
    Button: PropTypes.node,
}
