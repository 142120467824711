import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import classes from 'classnames'

import {ReactComponent as IconStar} from 'ipmp-react-ui/icons/star.svg'
import Tooltip from 'ipmp-react-ui/Tooltip'

import group from 'permissions/group/page'
import usePermission from 'hooks/usePermission'

import path from 'utils/path'
import __ from 'utils/i18n'

export default function GroupCell({row: {id, name, linkedToDealer, Icon = null}}) {
    const {isPermitted} = usePermission(group)

    return (
        <GroupTitle
            name={name}
            linkedToDealer={linkedToDealer}
            id={isPermitted ? id : null}
            Icon={Icon}
        />
    )
}

GroupCell.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        linkedToDealer: PropTypes.bool,
    }),
}

export const GroupTitle = ({id, name, linkedToDealer, className, Icon = null}) => {
    const content = (
        <>
            <div className="group-title--name">
                {Icon && <Icon className="group-title--icon" />}
                {name}
            </div>
            {linkedToDealer && (
                <Tooltip tooltip={__('Connected to dealer')}>
                    <IconStar />
                </Tooltip>
            )}
        </>
    )

    if (id) {
        return (
            <Link to={path('group', {id})} className={classes('group-title', className)}>
                {content}
            </Link>
        )
    }

    return <span className={classes('group-title', className)}>{content}</span>
}

GroupTitle.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    linkedToDealer: PropTypes.bool,
}
