import React, {useState} from 'react'
import PropTypes from 'prop-types'
import toArray from 'lodash-es/toArray'
import {useSelector} from 'react-redux'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'

import __ from 'utils/i18n'
import {addSrfDevice} from 'modules/forms/handlers'
import {selectPartitionsEnabled} from 'modules/features/store/selectors'
import {DismissConfirmation} from 'components/Modal'
import useForm from 'hooks/useForm'

import SecurityContent from '../SecurityContent'
import getRules from './rules'
import SRFFields from './SRFFields'

export function getEnrollmentDeviceByType(type, devices) {
    const enrollmentDevice = Object.values(devices)
        .filter((device) => {
            return device.name === type
        })
        .shift()

    return enrollmentDevice || false
}

const SRFBase = ({panelId, onClose, setProtocol, startAuto, isAutoLearnAvailable}) => {
    const [showDismissConfirmation, setShowDismissConfirmation] = useState(false)
    const {devices} = useSelector(({features}) => features.store.byIds[panelId].devices)
    const {handle, isLoading, form} = useForm(addSrfDevice)
    const [changed, setChanged] = useState(false)
    const [deviceId, setDeviceId] = useState('')
    const [showPartitions, setShowPartitions] = useState(false)
    const [deviceSubtype, setDeviceSubtype] = useState(null)
    const {isAvailable: partitionsEnabled} = useSelector((state) =>
        selectPartitionsEnabled(state, {panelId})
    )
    const cancelDismiss = () => setShowDismissConfirmation(false)

    const onChange = () => {
        setChanged(true)
    }

    const agreeDismiss = () => {
        onClose()
    }

    const dismiss = (e) => {
        e && e.preventDefault()

        if (changed) {
            setShowDismissConfirmation(true)
        } else {
            onClose()
        }
    }

    const onHandleClose = () => {
        changed ? setShowDismissConfirmation(true) : onClose()
    }

    const onSubmit = ({deviceId, partitions, userId, deviceSubtype}) => {
        return handle(
            panelId,
            deviceId.replace(/[^0-9A-Fa-f]/g, ''),
            partitions && toArray(partitions),
            userId,
            deviceSubtype
        )
    }

    const handleSRFDeviceIdChange = (e) => {
        const deviceId = e.target.value.replace(/[^0-9A-Fa-f]/g, '').slice(0, 6)
        setDeviceId(deviceId)
    }

    const handleSRFsubtypeChange = (e, value) => {
        const enrollmentDevice = getEnrollmentDeviceByType(value, devices)

        if (enrollmentDevice) {
            partitionsEnabled && setShowPartitions(enrollmentDevice.partitionsRequired)
            setDeviceSubtype(value)
        } else {
            setShowPartitions(false)
            setDeviceSubtype('')
        }
    }

    return (
        <Form
            onSubmit={onSubmit}
            onClose={dismiss}
            className="add-device-tab"
            rules={getRules(showPartitions)}
            isLoading={isLoading}
            onChange={onChange}
            {...form}
        >
            <CardClose onClick={onClose} />
            <CardHeader>{__('Enroll SRF sensor')}</CardHeader>
            <CardContent>
                <SecurityContent
                    panelId={panelId}
                    startAuto={startAuto}
                    isAutoLearnAvailable={isAutoLearnAvailable}
                >
                    <SRFFields
                        panelId={panelId}
                        deviceId={deviceId}
                        handleDeviceIdChange={handleSRFDeviceIdChange}
                        handleSRFsubtypeChange={handleSRFsubtypeChange}
                        showPartitions={showPartitions}
                        deviceSubtype={deviceSubtype}
                        setProtocol={setProtocol}
                    />
                    {showDismissConfirmation && (
                        <DismissConfirmation
                            dismiss={dismiss}
                            cancelDismiss={cancelDismiss}
                            agreeDismiss={agreeDismiss}
                            message={__(
                                'Do you really want to interrupt autolearn session?'
                            )}
                        />
                    )}
                </SecurityContent>
            </CardContent>
            <CardActions>
                <Button onClick={onHandleClose} flat>
                    {__('Dismiss')}
                </Button>
                <Button primary type="submit" className="add-device-submit">
                    {__('Add')}
                </Button>
            </CardActions>
        </Form>
    )
}

SRFBase.propTypes = {
    panelId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    setProtocol: PropTypes.func.isRequired,
    startAuto: PropTypes.func.isRequired,
    isAutoLearnAvailable: PropTypes.bool.isRequired,
}

export default SRFBase
