import React from 'react'
import PropTypes from 'prop-types'

import {makeBasic} from 'permissions/panel/configuration/actions'

import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import InteractiveBar from 'ipmp-react-ui/InteractiveBar'

import {__, __n} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'

const MakeBasicConfigurationInteractiveBarBase = ({
    makeBasic,
    pendingExport,
    commitBasic,
    resetChanges,
}) => {
    return (
        <InteractiveBar active={makeBasic || false}>
            {__n(
                '1 configuration element selected for creating new basic configuration',
                '%d configuration elements selected for creating new basic configuration',
                pendingExport
            )}
            <Button small onClick={commitBasic} disabled={!pendingExport}>
                {__('Create')}
            </Button>
            <Button small flat onClick={resetChanges}>
                {__('Dismiss')}
            </Button>
        </InteractiveBar>
    )
}

MakeBasicConfigurationInteractiveBarBase.propTypes = {
    pendingExport: PropTypes.number,
    commitBasic: PropTypes.func,
    resetChanges: PropTypes.func,
}

const MakeBasicConfigurationInteractiveBarVisibility = withVisibility()(
    MakeBasicConfigurationInteractiveBarBase
)

export default function MakeBasicConfigurationInteractiveBar(props) {
    let {isPermitted} = usePermission(makeBasic)
    const panel = usePanel(props)
    const barProps = {...props, ...panel}

    return (
        <MakeBasicConfigurationInteractiveBarVisibility
            {...barProps}
            isVisible={isPermitted}
        />
    )
}
