import {BILLING_PLUS_ENABLED_FEATURE} from './license'

export const BILLING_STATUS_VIEW_SHORT = 'SHORT'
export const BILLING_STATUS_VIEW_LONG = 'LONG'

export const BILLING_PLAN_STATUS_ACTIVATED = 'EPS_ACTIVATED'
export const BILLING_PLAN_STATUS_DEACTIVATED = 'EPS_DEACTIVATED'

export const NO_PLAN_ID = 'N00E'

export const isBillingPlusEnabled = (license) =>
    license?.features[BILLING_PLUS_ENABLED_FEATURE] !== undefined &&
    license?.features[BILLING_PLUS_ENABLED_FEATURE].value
