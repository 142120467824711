import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import usePermission from 'hooks/usePermission'

import {upgradeByFilter, upgradeList} from 'permissions/firmware/upgrade'

import {withVisibility} from 'containers/withVisibility'

import {useActions} from 'modules/higherOrder/useActions'
import {showFirmwarePatchTagUpdateModal} from 'modules/modals/actions'
import {selectFirmwarePatchTagUpdateListTotal} from 'modules/firmware/patchTagUpdate/selectors'
import {IdSetSubstractive} from 'modules/selection/store'

import __, {__n} from 'utils/i18n'

const ButtonVisibility = withVisibility()(Button)

export default function PatchTagUpdate({selection}) {
    const permissions = usePermission({
        list: upgradeList,
        filter: upgradeByFilter,
    })

    const upgradeAll = selection.isEmpty() || selection instanceof IdSetSubstractive
    const isPermitted = upgradeAll ? permissions.filter : permissions.list

    const {total: totalList} = useSelector(selectFirmwarePatchTagUpdateListTotal)

    const {onClick} = useActions(
        {
            onClick: () =>
                showFirmwarePatchTagUpdateModal({
                    panelIds: selection.toJS(),
                }),
        },
        [selection]
    )

    const count = selection.count()
    const total = selection.isEmpty() ? totalList : count

    const label =
        !total || total === Infinity
            ? __('Patch tag update all panels')
            : __n('Patch tag update %d panel', 'Patch tag update all %d panels', total)

    return (
        <ButtonVisibility isVisible={isPermitted} onClick={onClick} disabled={!total}>
            {label}
        </ButtonVisibility>
    )
}
