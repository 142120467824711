import React from 'react'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import {showConvertInstallerModal} from 'modules/modals/actions'

export default function InstallerConvertButton({row}) {
    const showConvertInstallerModalAction = useActions(showConvertInstallerModal)

    const onClick = (e) => {
        e.stopPropagation()
        e.preventDefault()

        showConvertInstallerModalAction(row)
    }

    return (
        <Button primary small onClick={onClick}>
            {__('Convert to operator')}
        </Button>
    )
}
