import {get, post, del} from '../http'

import * as mapper from 'api/system/settings.map'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {mapMfa, mapSelfRegistrationMode, mfaKeysMap} from 'api/system/settings.map'
import mapFormData from 'utils/mapFromData'

export function getCellularConnected() {
    return get('mmi/cellular')
}

export function setCellularConnected(data) {
    return post('mmi/cellular', data).catch(mapValidationErrors)
}

export function getBroadbandConnected() {
    return get('mmi/broadband')
}

export function setBroadbandConnected(data) {
    return post('mmi/broadband', data).catch(mapValidationErrors)
}

export function getCommonConnected() {
    return get('mmi/common')
}

export function setCommonConnected(data) {
    return post('mmi/common', data).catch(mapValidationErrors)
}

export function getSupervision() {
    return get('mmi/supervision')
}

export function setSupervision(data) {
    return post('mmi/supervision', data).catch(mapValidationErrors)
}

export function getRemoteInspection() {
    return get('mmi/rri')
}

export function setRemoteInspection(data) {
    return post('mmi/rri', data).catch(mapValidationErrors)
}

export function getInteractiveSession() {
    return get('mmi/interactive')
}

export function setInteractiveSession(data) {
    return post('mmi/interactive', data).catch(mapValidationErrors)
}

export function getUserNotifications() {
    return get('mmi/notifications')
}

export function setUserNotifications(data) {
    return post('mmi/notifications', data).catch(mapValidationErrors)
}

export function getAdvertisement() {
    // getAdvertising breaks with browser AdBlock, then getMOTD
    return get('mmi/motd')
}

export function setAdvertisement(data) {
    // setAdvertising breaks with browser AdBlock, then setMOTD
    return post('mmi/motd', data).catch(mapValidationErrors)
}

export function fetchMessageBrokers() {
    return get('mmi/brokers').then((brokers) => brokers.map(mapper.mapMessageBroker))
}

export function saveMessageBroker(data) {
    return post('mmi/brokers', mapper.mapMessageBroker(data))
        .then(mapper.mapMessageBroker)
        .catch(mapValidationErrors)
}

export function removeMessageBroker(data) {
    return del('mmi/brokers', {brokerId: data})
        .then((brokers) => brokers.map(mapper.mapMessageBroker))
        .catch(mapValidationErrors)
}

export function fetchSerialPorts() {
    return get('mmi/serialports').then((ports) => ports.map(mapper.mapSerialPort))
}

export function fetchAuthorizationSettings() {
    return get('mmi/authorizationsettings')
}

export function setAuthorizationSettings(data) {
    return post('mmi/authorizationsettings', data).catch((e) =>
        mapValidationErrors(mapper.mapValidatorInteractiveAuthorizationSettingsError(e))
    )
}

export function getRotation() {
    return get('mmi/rotation')
}

export function setRotation(data) {
    return post('mmi/rotation', data).catch(mapValidationErrors)
}

export function getMfa() {
    return get('/mmi/mfa').then(mapMfa)
}

export function setMfa(data) {
    data = mapFormData(data, mfaKeysMap)
    return post('/mmi/mfa', data).catch((e) => mapValidationErrors(e, mfaKeysMap))
}

export function getGoogleSettings() {
    return get('/mmi/googlesettings')
}

export function setGoogleSettings(data) {
    return post('/mmi/googlesettings', data).catch(mapValidationErrors)
}

export function getPgmActivationTimeSlot() {
    return get('/mmi/pgmactivationtimeslot').then(mapper.mapPgmActivationTimeSlot)
}

export function setPgmActivationTimeSlot(data) {
    return post('/mmi/pgmactivationtimeslot', data)
        .then(mapper.mapPgmActivationTimeSlot)
        .catch(mapValidationErrors)
}

export function getSelfRegistration() {
    return get('/mmi/self-registration').then(mapper.mapSelfRegistration)
}

export function setSelfRegistration(data) {
    return post('/mmi/self-registration', {
        mode: Object.entries(mapSelfRegistrationMode).reduce(
            (acc, [key, mode]) => (mode === +data.mode ? key : acc),
            null
        ),
        default_role: +data.defaultRole,
    })
        .then(mapper.mapSelfRegistration)
        .catch(mapValidationErrors)
}
