import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import DateTime from 'ipmp-react-ui/DateTime'
import ProgressBar from 'ipmp-react-ui/ProgressBar'

import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as IconCompleted} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as IconEmpty} from 'ipmp-react-ui/icons/hourglass-empty.svg'

import {__} from 'utils/i18n'

export default class StatusReportCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            status: PropTypes.string,
            percentage: PropTypes.number,
            finishedAt: PropTypes.object,
        }),
    }

    render() {
        const {
            row: {status, percentage, finishedAt},
        } = this.props

        switch (status) {
            case 'failed':
                return (
                    <DateTime
                        className="danger reports-table-state-report-cell"
                        Icon={IconAlert}
                        datetime={finishedAt || {}}
                        withSeconds
                        multiLine
                    />
                )
            case 'start':
            case 'handled':
                return (
                    <ProgressBar
                        max={100}
                        value={percentage || 0}
                        className="reports-table-state-report-cell"
                    />
                )
            case 'succeeded':
                return (
                    <DateTime
                        Icon={IconCompleted}
                        datetime={finishedAt || {}}
                        className="reports-table-state-report-cell"
                        withSeconds
                        multiLine
                    />
                )
            case 'new':
            case null:
                return (
                    <Definition
                        className="empty reports-table-state-report-cell"
                        Icon={IconEmpty}
                        title={__('No results yet')}
                    />
                )
            default:
                return null
        }
    }
}
