import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {createReport} from 'permissions/panels/selection'

const ButtonVisibility = withVisibility()(Button)

export default function CreateReportButton({onClick}) {
    const {isPermitted} = usePermission(createReport)

    return (
        <ButtonVisibility
            Icon={IconPlus}
            isVisible={isPermitted}
            onClick={onClick}
            primary
        >
            {__('Add Report')}
        </ButtonVisibility>
    )
}
