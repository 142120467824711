import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const fetch = createAction('DEVICES/LIST/FETCH', (panelId) => ({panelId}))

export const receive = createAction('DEVICES/LIST/RECEIVE', null, (_, panelId) => ({
    panelId,
}))
export const update = createAction('DEVICES/LIST/UPDATE', toArray, (_, panelId) => ({
    panelId,
}))

export const refreshRssi = createAction('DEVICES/LIST/REFRESH_RSSI', (panelId) => ({
    panelId,
}))
export const refreshGSM = createAction('DEVICES/LIST/REFRESH_GSM', (panelId) => ({
    panelId,
}))
export const dlsDisconnect = createAction('DEVICES/LIST/DLS_DISCONNECT', (panelId) => ({
    panelId,
}))
export const setRarelyUsed = createAction(
    'DEVICES/LIST/SET_RARELY_USED',
    (panelId, deviceId, enabled) => ({
        panelId,
        deviceId,
        enabled,
    })
)
export const removeDevice = createAction(
    'DEVICES/LIST/REMOVE_DEVICE',
    (panelId, deviceId) => ({panelId, deviceId})
)
export const setSoak = createAction(
    'DEVICES/LIST/SET_SOAK',
    (panelId, deviceId, enabled) => ({
        panelId,
        deviceId,
        enabled,
    })
)
export const setBypass = createAction('DEVICES/LIST/SET_BYPASS', (panelId, states) => ({
    panelId,
    states,
}))
export const confirmProgress = createAction(
    'DEVICES/LIST/CONFIRM_PROGRESS',
    (panelId, deviceIds, type, processKey) => ({
        panelId,
        deviceIds: toArray(deviceIds),
        type,
        processKey,
    })
)
export const finishProgress = createAction(
    'DEVICES/LIST/FINISH_PROGRESS',
    (panelId, processKey, type) => ({
        panelId,
        processKey,
        type,
    })
)
export const revertProgress = createAction(
    'DEVICES/LIST/REVERT_PROGRESS',
    (panelId, deviceIds, type) => ({panelId, deviceIds: toArray(deviceIds), type})
)
export const renameDevice = createAction(
    'DEVICES/LIST/RENAME_DEVICE',
    (panelId, deviceType, zone, name) => ({panelId, deviceType, zone, name})
)

export const startPoll = createAction('DEVICES/LIST/START_POLL', (panelId) => ({panelId}))

export const stopPoll = createAction('DEVICES/LIST/STOP_POLL')

export const startClearDevice = createAction(
    'DEVICES/LIST/START_CLEAR',
    (panelId, protocol) => ({
        panelId,
        protocol,
    })
)

export const stopClearDevice = createAction(
    'DEVICES/LIST/STOP_CLEAR',
    (panelId, protocol) => ({
        panelId,
        protocol,
    })
)

export const triggerReboot = createAction('DEVICES/LIST/TRIGGER/REBOOT', (panelId) => ({
    panelId,
}))

export const triggerEngineeringReset = createAction(
    'DEVICES/LIST/TRIGGER/ENGINEERING_RESET',
    (panelId) => ({
        panelId,
    })
)

export const triggerRemindMasterUserCode = createAction(
    'DEVICES/LIST/TRIGGER/REMIND_MASTER_USER_CODE',
    (panelId) => ({
        panelId,
    })
)

export const fetchLogOutsidePm = createAction(
    'DEVICES/LIST/FETCH_LOG_OUTISIDE_PM',
    (panelId) => ({
        panelId,
    })
)
