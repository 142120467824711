import React from 'react'

import path from 'utils/path'

import InteractiveUsersPage from './InteractiveUsersPage'

export default class {
    path = path('accounts')

    element = (<InteractiveUsersPage />)
}
