import {get} from 'lodash-es'
import {createSelector} from 'reselect'

export function selectLicense({
    system: {
        settings: {license},
    },
}) {
    return {
        license: license,
    }
}

export function selectServerVersion({
    system: {
        settings: {serverVersion},
    },
}) {
    return {
        serverVersion,
    }
}

export const selectMessageBrokers = (state) =>
    get(state, ['system', 'settings', 'messageBrokers', 'data'], [])
export const selectSerialPorts = (state) =>
    get(state, ['system', 'settings', 'serialPorts', 'data'], [])

export const selectAvailableSerialPorts = createSelector(
    [selectMessageBrokers, selectSerialPorts, (_, {defaultValue}) => defaultValue],
    (brokers, ports, value) => ({
        serialPorts: ports.filter(
            (port) =>
                !brokers.some((broker) => broker.serial === port.id) || port.id === value
        ),
    })
)

export const selectMfa = createSelector(
    (state) => state.system.settings.mfa.data,
    selectMessageBrokers,
    (mfa, messageBrokers) => ({
        ...mfa,
        messageBrokers,
        messageBroker: messageBrokers.find((broker) => broker.id === mfa.smsBroker),
    })
)

export const selectGoogleSettings = (state) => state.system.settings.googleSettings.data
export const selectPgmActivationTimeSlot = (state) =>
    state.system.settings.pgmActivationTimeSlot.data
export const selectSelfRegistration = (state) =>
    state.system.settings.selfRegistration.data
