import React from 'react'
import {compose} from 'redux'

import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import useDevices from 'hooks/pages/useDevices'
import {withVisibility} from 'containers/withVisibility'
import {withFeatureRejection} from 'containers/withFeature'
import {selectAddDeviceFeature} from 'modules/features/store/selectors'
import Void from 'components/Void'
import useEnrollPermissions from 'hooks/panel/devices/useEnrollPermissions'

const ButtonVisibleWithFeature = compose(
    withVisibility(),
    withFeatureRejection(selectAddDeviceFeature, Void)
)(Button)

export default function AddCameraButton({primary}) {
    const {camerasPermitted} = useEnrollPermissions()
    const devicesProps = useDevices()

    return (
        <>
            <ButtonVisibleWithFeature
                {...devicesProps}
                Icon={PlusIcon}
                isVisible={camerasPermitted}
                label={__('Add camera')}
                onClick={devicesProps.showAddCamera}
                primary
            />
        </>
    )
}
