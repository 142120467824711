import dataMapper from 'utils/dataMapper'
import parseDate from 'api/base/parseDate'
import {err} from 'utils/log'
import {REPORT_ONE_TIME} from 'constants/reportTypes'

const mapExportTypes = (types) => {
    return types.split(',')
}

const parseSchedule = ({ret_schedule_data: data, ret_id: reportId}) => {
    let schedule = {
        every: null,
        schedule: null,
    }

    try {
        data = JSON.parse(data)
        schedule = {
            ...schedule,
            ...data,
        }
        schedule.every = data.settings ? parseInt(data.settings.every) : 1
        schedule.schedule = parseDate(data.schedule)
    } catch (error) {
        err(`Report ${reportId} parse schedule data error: ${error}`)
    }

    return schedule
}

export const mapReports = dataMapper((report) => {
    const schedule = parseSchedule(report)

    return {
        id: parseInt(report.ret_id),
        unitReportId: parseInt(report.utr_id),
        name: report.ret_name,
        groupId: report.utg_id,
        groupName: report.utg_name,
        groupIsDealer: !!parseInt(report.utg_is_dealer),
        panelId: report.unt_id,
        panelSerial: report.unt_serial,
        exportTypes: mapExportTypes(report.export_types),
        isReviewed: parseInt(report.is_not_reviewed) === 0,
        isActive: report.ret_active === 'yes',
        created: parseDate(report.ret_created_timestamp),
        isFinished:
            report.ret_finished === 'y' ||
            (report.utr_status === 'succeeded' && schedule.pattern === REPORT_ONE_TIME),
        next: parseDate(report.ret_next_report_timestamp),
        finishedAt: parseDate(report.utr_finished_timestamp),
        status: report.utr_status,
        percentage: parseInt(report.utr_status_percentages),
        schedule,
    }
})

export const keysMap = {
    id: 'ret_id',
    unitReportId: 'utr_id',
    name: 'ret_name',
    exportTypes: 'export_types',
    isReviewed: 'is_not_reviewed',
    isActive: 'ret_active',
    created: 'ret_created_timestamp',
    isFinished: 'ret_finished',
    next: 'ret_next_report_timestamp',
    finishedAt: 'utr_finished_timestamp',
    status: 'utr_status',
    percentage: 'utr_status_percentages',
}
