import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {registerInteractiveUser} from 'modules/forms/handlers'

const rules = {
    email: {
        presence: true,
        emailRFC: true,
    },
}

export default function RegisterInteractiveUser({hide}) {
    const {form, handle, isLoading} = useForm(registerInteractiveUser)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Register New Account')}
        >
            <Input label={__('Email')} name="email" />
        </ModalCardForm>
    )
}

RegisterInteractiveUser.propTypes = {
    hide: PropTypes.func.isRequired,
}
