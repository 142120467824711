import {takeEvery, all, put, call} from 'redux-saga/effects'

import * as api from 'api/roles'
import {editRole} from 'modules/forms/handlers'
import watch from 'modules/higherOrder/watch'

import * as actions from './actions'

export default function* () {
    yield all([
        takeEvery([editRole.SUCCESS], watchEditRole),
        takeEvery(actions.fetch, watch(fetch)),
    ])
}

function* watchEditRole({payload: {result}}) {
    yield put(actions.update(result))
}

export function* fetch() {
    const {rows} = yield call(api.fetch)
    yield put(actions.update(rows))
}
