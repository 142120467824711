import {createAction} from 'redux-actions'
import toIds from 'utils/toIds'

export const fetch = createAction('PANELS/ONE/FETCH', (panelId) => ({panelId}))
export const refreshState = createAction('PANELS/ONE/REFRESH_STATE', (panelId) => ({
    panelId,
}))
export const setError = createAction('PANELS/ONE/SET_ERROR')
export const setLoading = createAction('PANELS/ONE/SET_LOADING')
export const remove = createAction('PANELS/ONE/REMOVE', (panels) => toIds(panels))
export const fetchUsers = createAction('PANELS/ONE/FETCH_USERS', (panelId) => ({panelId}))
export const setUserAppState = createAction(
    'PANELS/ONE/SET_USER_APP_STATE',
    (rows, state) => ({
        ids: toIds(rows),
        userApp: state,
    })
)

export const setConfigurationAppState = createAction(
    'PANELS/ONE/SET_CONFIGURATOR_APP_STATE',
    (rows, state) => ({
        ids: toIds(rows),
        configuratorApp: state,
    })
)

export const startPollPanelData = createAction(
    'PANELS/ONE/START_POLL_DATA',
    (panelId) => ({panelId})
)
export const stopPollPanelData = createAction('PANELS/ONE/STOP_POLL_DATA')
export const skipDelayPollPanelData = createAction('PANELS/ONE/SKIP_DELAY_POLL_DATA')
export const purgeStore = createAction('PANELS/ONE/PURGE')
export const fetchTestsHistory = createAction('PANELS/ONE/FETCH_TESTS', (panelId) => ({
    panelId,
}))
export const startPollTestsStatuses = createAction(
    'PANELS/ONE/START_POLL_TESTS_STATUSES',
    (panelId, tokens) => ({
        panelId,
        tokens,
    })
)

export const stopPollTestsStatuses = createAction('PANELS/ONE/STOP_POLL_TESTS_STATUSES')

export const updateTestsHistory = createAction('PANELS/ONE/UPDATE_TESTS', (payload) => ({
    payload,
}))

export const updateTestsStatuses = createAction(
    'PANELS/ONE/UPDATE_TESTS_STATUSES',
    (payload) => ({
        payload,
    })
)

export const runPanelTest = createAction(
    'PANELS/ONE/RUN_PANEL_TESTS',
    ({panelId, tests}) => ({panelId, tests})
)

export const requestCustomerApproval = createAction(
    'PANELS/ONE/REQUEST_CUSTOMER_APPROVAL',
    (panelId) => ({panelId})
)

export const requestCustomerApprovalsBatch = createAction(
    'PANELS/ONE/REQUEST_CUSTOMER_APPROVAL/BATCH',
    (panelIds) => ({panelIds})
)

export const setTestStatusId = createAction(
    'PANELS/ONE/SET_TEST_STATUS_ID',
    (panelId, processes) => ({panelId, processes})
)

export const eventInitiate = createAction(
    'PANELS/ONE/EVENT_INITIATE',
    (panelId, eventType, partitions) => ({
        panelId,
        eventType,
        partitions,
    })
)
