import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Select, {Option} from 'ipmp-react-ui/Select'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'
import {selectSelfRegistration} from 'modules/system/settings/selectors'
import {
    SELF_REGISTRATION_MODE_DISABLED,
    SELF_REGISTRATION_MODES,
} from 'constants/settings'
import {DEFAULT_TYPE} from 'constants/role'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch} from 'modules/roles/store/actions'
import {selectRolesStore} from 'modules/roles/store/selectors'

const getRules = () => ({
    mode: {
        presence: {
            message: () => __('Registration mode is required'),
        },
    },
    defaultRole: {},
})

export default function SelfRegistration({hide}) {
    const {mode, defaultRole} = useSelector(selectSelfRegistration)
    const {form, handle, isLoading} = useForm(setSystemSettings.SelfRegistration)
    const [fetchRoles] = useActions([fetch])
    useEffect(() => {
        fetchRoles()
    }, [])
    const roles = useSelector(selectRolesStore)
    const [_mode, _setMode] = useState(mode)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={getRules(_mode)}
            confirmOnDismiss
            isLoading={isLoading}
            onSubmit={handle}
            header={__('Self-Registration Settings')}
            className="selfRegistration-form"
        >
            <Select
                name="mode"
                defaultValue={mode}
                label={__('Registration Mode')}
                onChange={(e, value) => _setMode(+value)}
                disabled={true}
            >
                {Object.entries(SELF_REGISTRATION_MODES).map(([mode, label]) => (
                    <Option key={+mode} label={label} value={+mode} />
                ))}
            </Select>
            {_mode !== SELF_REGISTRATION_MODE_DISABLED && (
                <>
                    <Select
                        name="defaultRole"
                        label={__('Default Role')}
                        defaultValue={defaultRole}
                        optional={__('Without default role')}
                    >
                        {Object.entries(roles)
                            .filter(([, {type}]) => type === DEFAULT_TYPE)
                            .map(([id, role]) => (
                                <Option key={+id} label={role.name} value={+id} />
                            ))}
                    </Select>
                    <Disclaimer
                        className="settings-card-disclaimer"
                        children={__(
                            'The Default Role will be applied if a Dealer ID was not set during registration.'
                        )}
                    />
                </>
            )}
        </ModalCardForm>
    )
}
