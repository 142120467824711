import {createSelector} from 'reselect'
import {DEFAULT_TYPE, INSTALLER_TYPE} from 'constants/role'

export const selectIsDefaultRoleType = createSelector(
    (state) => state.auth.sign.user.roleType,
    (roleType) => DEFAULT_TYPE === roleType
)

export const selectIsInstallerRoleType = createSelector(
    (state) => state.auth.sign.user.roleType,
    (roleType) => INSTALLER_TYPE === roleType
)

export const selectIsLoading = createSelector(
    (state) => state.auth.sign.isLoading,
    (isLoading) => !!isLoading
)
