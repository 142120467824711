import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {register} from 'permissions/interactiveUsers/actions'
import {showRegisterInteractiveUserModal} from 'modules/modals/actions'

const ButtonVisibility = withVisibility()(Button)

export default function AddInteractiveUser() {
    const {isPermitted} = usePermission(register)
    const onClick = useActions(showRegisterInteractiveUserModal)

    return (
        <ButtonVisibility
            Icon={IconPlus}
            onClick={onClick}
            isVisible={isPermitted}
            label={__('Add Account')}
            primary
        />
    )
}
