import React, {useState} from 'react'
import PropTypes from 'prop-types'
import toArray from 'lodash-es/toArray'
import isEmpty from 'lodash-es/isEmpty'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import Form from 'ipmp-react-ui/Form'
import {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'

import __ from 'utils/i18n'
import {DELIM, POWERG_ENROL_ID_PREFIX_LENGTH} from 'constants/device'
import {DEVICE_SUBTYPE_COMMON_KEYFOB} from 'constants/deviceSubtype'
import {selectPartitionsEnabled} from 'modules/features/store/selectors'
import {DismissConfirmation} from 'components/Modal'
import useForm from 'hooks/useForm'
import {addDevice} from 'modules/forms/handlers'

import SecurityContent from '../SecurityContent'
import getRules from './rules'
import PowerGFields from './PowerGFields'
import getEnrollmentDevice from './getEnrollmentDevice'

const BOTTOM_KEY_CODE_LIMIT = 48
const TOP_KEY_CODE_LIMIT = 57
const DEFAULT_IS_ZONE_APPLICABLE = false

const PowerGBase = ({
    panelId,
    onClose,
    setProtocol,
    startAuto,
    isSRFEnabled,
    isAutoLearnAvailable,
}) => {
    const {devices} = useSelector(({features}) => features.store.byIds[panelId].devices)
    const {handle, isLoading, form} = useForm(addDevice)
    const [showDismissConfirmation, setShowDismissConfirmation] = useState(false)
    const {isAvailable: partitionsEnabled} = useSelector((state) =>
        selectPartitionsEnabled(state, {panelId})
    )

    const [changed, setChanged] = useState(false)
    const [deviceId, setDeviceId] = useState('')
    const [showUser, setShowUser] = useState(false)
    const [showPartitions, setShowPartitions] = useState(false)
    const [isUserNumber, setIsUserNumber] = useState(false)
    const [deviceSubtype, setDeviceSubtype] = useState(null)
    const [digitAdded, setDigitAdded] = useState(null)
    const [isZoneApplicable, setIsZoneApplicable] = useState(DEFAULT_IS_ZONE_APPLICABLE)

    const cancelDismiss = () => setShowDismissConfirmation(false)

    const onChange = () => {
        setChanged(true)
    }

    const agreeDismiss = () => {
        onClose()
    }

    const dismiss = (e) => {
        e && e.preventDefault()

        if (changed) {
            setShowDismissConfirmation(true)
        } else {
            onClose()
        }
    }

    const onHandleClose = () => {
        changed ? setShowDismissConfirmation(true) : onClose()
    }

    const onSubmit = ({zoneId, deviceId, partitions, userId}) => {
        return handle(
            panelId,
            zoneId,
            deviceId.replace(/\D/g, ''),
            partitions && toArray(partitions),
            userId
        )
    }

    const handleDeviceIdChange = (e) => {
        const digits = e.target.value.replace(/\D/g, '')
        const hasDelim = e.target.value.indexOf(DELIM) === POWERG_ENROL_ID_PREFIX_LENGTH

        let deviceId = digits.slice(0, POWERG_ENROL_ID_PREFIX_LENGTH)

        const enrollmentDevice = getEnrollmentDevice(deviceId, devices)

        const isEnrollInformationDefined =
            !isEmpty(deviceId) && // Device ID entered
            !isEmpty(enrollmentDevice) && // There is an information in feature-set for taken prefix
            enrollmentDevice.enroll === true // Enrollment is allowed

        if (isEnrollInformationDefined) {
            setShowUser(enrollmentDevice.usersRequired)
            partitionsEnabled && setShowPartitions(enrollmentDevice.partitionsRequired)
            setDeviceSubtype(enrollmentDevice.name)
            setIsZoneApplicable(enrollmentDevice.isZoneApplicable)
        } else {
            setShowUser(false)
            setShowPartitions(false)
            setDeviceSubtype('')
            setIsZoneApplicable(DEFAULT_IS_ZONE_APPLICABLE)
        }

        if (
            hasDelim ||
            (digits.length === POWERG_ENROL_ID_PREFIX_LENGTH && digitAdded) ||
            digits.length > POWERG_ENROL_ID_PREFIX_LENGTH
        ) {
            deviceId += DELIM
        }

        deviceId += digits.slice(POWERG_ENROL_ID_PREFIX_LENGTH)
        setDeviceId(deviceId)
    }

    const handleDeviceIdKeyDown = (e) => {
        setDigitAdded(
            e.keyCode >= BOTTOM_KEY_CODE_LIMIT && e.keyCode <= TOP_KEY_CODE_LIMIT
        )
    }

    const isKeyfob = (deviceSubtype) => {
        return deviceSubtype.indexOf(DEVICE_SUBTYPE_COMMON_KEYFOB) > 0
    }

    const setUserWithoutPin = (e) => {
        setIsUserNumber(e.target.checked)
    }

    return (
        <Form
            onSubmit={onSubmit}
            onClose={dismiss}
            className="add-device-tab"
            rules={getRules(showUser, showPartitions, devices, isZoneApplicable)}
            isLoading={isLoading}
            onChange={onChange}
            {...form}
        >
            <CardClose onClick={onClose} />
            <CardHeader>{__('Enroll PowerG device')}</CardHeader>
            <CardContent>
                <SecurityContent
                    panelId={panelId}
                    startAuto={startAuto}
                    isAutoLearnAvailable={isAutoLearnAvailable}
                >
                    <PowerGFields
                        panelId={panelId}
                        deviceId={deviceId}
                        handleDeviceIdChange={handleDeviceIdChange}
                        handleDeviceIdKeyDown={handleDeviceIdKeyDown}
                        showPartitions={showPartitions}
                        showUser={showUser}
                        isUserNumber={isUserNumber}
                        deviceSubtype={deviceSubtype}
                        isKeyfob={isKeyfob}
                        setUserWithoutPin={setUserWithoutPin}
                        setProtocol={setProtocol}
                        isSRFEnabled={isSRFEnabled}
                        isZoneApplicable={isZoneApplicable}
                    />

                    {showDismissConfirmation && (
                        <DismissConfirmation
                            dismiss={dismiss}
                            cancelDismiss={cancelDismiss}
                            agreeDismiss={agreeDismiss}
                            message={__(
                                'Do you really want to interrupt autolearn session?'
                            )}
                            title={__('Stop autolearn session')}
                        />
                    )}
                </SecurityContent>
            </CardContent>
            <CardActions>
                <Button onClick={onHandleClose} flat>
                    {__('Dismiss')}
                </Button>
                <Button primary type="submit" className="add-device-submit">
                    {__('Add')}
                </Button>
            </CardActions>
        </Form>
    )
}

PowerGBase.propTypes = {
    panelId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    setProtocol: PropTypes.func.isRequired,
    startAuto: PropTypes.func.isRequired,
    isSRFEnabled: PropTypes.bool.isRequired,
    isAutoLearnAvailable: PropTypes.bool.isRequired,
}

export default PowerGBase
