import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {selectSelfRegistration} from 'modules/system/settings/selectors'
import {SELF_REGISTRATION_MODES} from 'constants/settings'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch} from 'modules/roles/store/actions'
import {selectRolesStore} from 'modules/roles/store/selectors'
import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const SelfRegistration = () => {
    const {mode, defaultRole} = useSelector(selectSelfRegistration)
    const [fetchRoles] = useActions([fetch])
    useEffect(() => {
        fetchRoles()
    }, [])
    const roles = useSelector(selectRolesStore)

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Registration Mode')}</InfoCardTileTitle>
                <InfoCardTileContent>{SELF_REGISTRATION_MODES[mode]}</InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Default role')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(roles[defaultRole]?.name)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default SelfRegistration
