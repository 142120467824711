import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import {bindActionCreators, compose} from 'redux'

import Input from 'ipmp-react-ui/Input'
import Select from 'ipmp-react-ui/Select'
import {Option} from 'ipmp-react-ui/Select'
import ModalCardForm from 'ui/ModalCardForm'
import {FormSection} from 'ipmp-react-ui/Form'
import Autocomplete from 'ipmp-react-ui/Autocomplete'

import {__, __n} from 'utils/i18n'
import useForm from 'hooks/useForm'
import Content from './Create/Content'
import Schedule from './Create/Schedule'
import rules from './Create/utils/rules'
import {clear} from 'modules/forms/actions'
import Recurrence from './Create/Recurrence'
import {createReport} from 'modules/forms/handlers'
import prepareParams from './Create/utils/prepareParams'
import withModalLoader from 'containers/withModalLoader'
import {useActions} from 'modules/higherOrder/useActions'
import RangeOfRecurrence from './Create/RangeOfRecurrence'
import {fetch as fetchPmaxIndexes} from 'modules/reports/pmaxIndexes/actions'
import {fetch as fetchMeteoMetrics} from 'modules/reports/meteoMetrics/actions'
import {fetch as fetchContentOptions} from 'modules/reports/contentOptions/actions'
import {fetch as fetchTransportEmails} from 'modules/reports/transportEmails/actions'
import {
    getPatternLabel,
    REPORT_DAILY,
    REPORT_MONTHLY,
    REPORT_ONE_TIME,
    REPORT_WEEKLY,
} from 'constants/reportTypes'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchGroups} from 'modules/groups/select/actions'

const reportTypes = {
    onetime: REPORT_ONE_TIME,
    daily: REPORT_DAILY,
    weekly: REPORT_WEEKLY,
    monthly: REPORT_MONTHLY,
}

const GroupSelect = withSelectLoader(
    fetchGroups,
    (state) => state.groups.select.isLoading,
    (state) =>
        Object.values(state.groups.store.byIds).map(({id, name}) => ({
            label: name,
            value: id,
        })),
    10
)(Autocomplete)

const CreateReport = ({
    contentOptions,
    meteoMetrics,
    pmaxIndexes,
    transportEmails,
    panelIds,
    onSuccess,
    hide,
}) => {
    const {form, handle, isLoading} = useForm(createReport)

    const [reportType, setReportType] = useState('onetime')

    const changeReportType = (e, reportType) => {
        setReportType(reportType)
    }

    const onSubmit = async (formData) => {
        const params = prepareParams({
            ...formData,
            panelIds,
        })

        try {
            await handle(params)
            onSuccess && onSuccess()
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    const header = () => {
        if (Array.isArray(panelIds)) {
            return __n(
                'Create report for %s panel',
                'Create report for %s panels',
                panelIds.length,
                [panelIds.length]
            )
        }

        return __('Create Report for group')
    }

    const clearForm = useActions(clear)

    useEffect(() => {
        return clearForm
    }, [])

    const computedRules = useMemo(() => {
        if (panelIds === null) {
            return {...rules, groupId: {presence: true}}
        }

        return rules
    }, [panelIds])

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            wide={2}
            rules={computedRules}
            confirmOnDismiss
            header={header()}
            onSubmit={onSubmit}
            isLoading={isLoading}
            className="create-report-modal-form"
        >
            <Input
                autoFocus
                label={__('Report Name')}
                name="name"
                dataTestId="report-name"
            />

            {panelIds === null && (
                <GroupSelect showTypeForMore label={__('Group')} name="groupId" />
            )}

            <div className="cols cols--stretch">
                <FormSection className="col" title={__('Report Options')}>
                    <Select
                        onChange={changeReportType}
                        value={reportType}
                        label={__('Report Type')}
                        name="schedule.pattern"
                        dataTestId="report-type"
                    >
                        {Object.keys(reportTypes).map((key) => (
                            <Option
                                key={key}
                                value={key}
                                label={getPatternLabel(reportTypes[key])}
                            />
                        ))}
                    </Select>

                    <Recurrence {...{reportType}} />

                    {reportType !== 'onetime' && <RangeOfRecurrence />}

                    <Schedule />
                </FormSection>

                <Content
                    contentOptions={contentOptions}
                    meteoMetrics={meteoMetrics}
                    pmaxIndexes={pmaxIndexes}
                    transportEmails={transportEmails}
                    panelIds={panelIds}
                />
            </div>
        </ModalCardForm>
    )
}

CreateReport.propTypes = {
    reportTypes: PropTypes.object,
    contentOptions: PropTypes.object,
    meteoMetrics: PropTypes.object,
    pmaxIndexes: PropTypes.object,
    transportEmails: PropTypes.array,
    hide: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    panelIds: PropTypes.array,
}

export default compose(
    connect(
        ({
            reports,
            auth: {
                sign: {
                    user: {email},
                },
            },
        }) => ({
            isLoading:
                reports.contentOptions.isLoading ||
                reports.meteoMetrics.isLoading ||
                reports.pmaxIndexes.isLoading ||
                reports.transportEmails.isLoading,
            contentOptions: reports.contentOptions.options,
            meteoMetrics: reports.meteoMetrics.options,
            pmaxIndexes: reports.pmaxIndexes.indexes,
            transportEmails: reports.transportEmails.emails.includes(email)
                ? reports.transportEmails.emails
                : [email, ...reports.transportEmails.emails],
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    fetchContentOptions,
                    fetchMeteoMetrics,
                    fetchPmaxIndexes,
                    fetchTransportEmails,
                },
                dispatch
            )
    ),

    withModalLoader(
        ({
            fetchContentOptions,
            fetchMeteoMetrics,
            fetchPmaxIndexes,
            fetchTransportEmails,
        }) => {
            fetchPmaxIndexes()
            fetchContentOptions()
            fetchMeteoMetrics()
            fetchTransportEmails()
        }
    )
)(CreateReport)
