import {selectPerPage} from 'modules/settings/selectors'
import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import * as api from 'api/installers'
import * as actions from './actions'

import {update, purge} from '../store/actions'

import listSaga from 'modules/higherOrder/createListSaga'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {
    registerInstaller,
    changeInstallerCode,
    convertInstaller,
} from 'modules/forms/handlers'
import {POLL_INSTALLERS} from 'configs/pollers'
import toIds from 'utils/toIds'
import {__} from 'utils/i18n'
import {snackShow} from 'modules/snacks'

const selector = (state) => ({
    ...state.installers.list,
    perPage: selectPerPage(state, 'installers'),
    listRoute: 'installers',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_INSTALLERS, watchPoll),
        takeEvery(registerInstaller.SUCCESS, watchRegisterSuccess),
        takeEvery(convertInstaller.SUCCESS, watchConvertSuccess),
        takeEvery(changeInstallerCode.SUCCESS, watchChangeCodeSuccess),
        takeEvery(changeInstallerCode.FAILURE, watchChangeCodeFailure),
    ])
}

export function* watchPoll() {
    const state = yield select(selector)

    const {rows, count} = yield call(api.fetch, state)

    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

watchPoll.onError = function* (error) {
    yield put(actions.receive(error))
}

export function* watchRegisterSuccess() {
    yield put(actions.fetch())
    yield put(snackShow(__('Installer was registered successfully')))
}

export function* watchConvertSuccess() {
    yield put(actions.fetch())
    yield put(snackShow(__('Installer was converted to operator successfully')))
}

export function* watchChangeCodeSuccess() {
    yield put(actions.fetch())
    yield put(snackShow(__('Set installer code process is initiated successfully')))
}

function* watchChangeCodeFailure({payload: {error}}) {
    if (error) {
        yield put(snackShow(error))
    }
}
