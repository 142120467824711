import {createAction} from 'redux-actions'

export const fetchCellularConnected = createAction(
    'SYSTEM_SETTINGS/CELLULAR_CONNECTED/FETCH'
)
export const updateCellularConnected = createAction(
    'SYSTEM_SETTINGS/CELLULAR_CONNECTED/UPDATE'
)
export const fetchBroadbandConnected = createAction(
    'SYSTEM_SETTINGS/BROADBAND_CONNECTED/FETCH'
)
export const updateBroadbandConnected = createAction(
    'SYSTEM_SETTINGS/BROADBAND_CONNECTED/UPDATE'
)

export const fetchCommonConnected = createAction('SYSTEM_SETTINGS/COMMON_CONNECTED/FETCH')
export const updateCommonConnected = createAction(
    'SYSTEM_SETTINGS/COMMON_CONNECTED/UPDATE'
)

export const fetchSupervision = createAction('SYSTEM_SETTINGS/SUPERVISION/FETCH')
export const updateSupervision = createAction('SYSTEM_SETTINGS/SUPERVISION/UPDATE')

export const fetchRemoteInspection = createAction(
    'SYSTEM_SETTINGS/REMOTE_INSPECTION/FETCH'
)
export const updateRemoteInspection = createAction(
    'SYSTEM_SETTINGS/REMOTE_INSPECTION/UPDATE'
)

export const fetchInteractiveSession = createAction(
    'SYSTEM_SETTINGS/INTERACTIVE_SESSION/FETCH'
)
export const updateInteractiveSession = createAction(
    'SYSTEM_SETTINGS/INTERACTIVE_SESSION/UPDATE'
)

export const fetchUserNotifications = createAction(
    'SYSTEM_SETTINGS/USER_NOTIFICATION/FETCH'
)
export const updateUserNotifications = createAction(
    'SYSTEM_SETTINGS/USER_NOTIFICATION/UPDATE'
)
export const fetchAdvertisement = createAction('SYSTEM_SETTINGS/ADVERTISEMENT/FETCH')
export const updateAdvertisement = createAction('SYSTEM_SETTINGS/ADVERTISEMENT/UPDATE')

export const fetchMessageBrokers = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/FETCH')
export const updateMessageBrokers = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/UPDATE')
export const addMessageBrokerToStore = createAction(
    'SYSTEM_SETTINGS/MESSAGE_BROKERS/ADD_TO_STORE'
)
export const removeMessageBroker = createAction('SYSTEM_SETTINGS/MESSAGE_BROKERS/REMOVE')
export const removeMessageBrokerFromStore = createAction(
    'SYSTEM_SETTINGS/MESSAGE_BROKERS/REMOVE_FROM_STORE'
)
export const saveMessageBrokerInStore = createAction(
    'SYSTEM_SETTINGS/MESSAGE_BROKERS/SAVE_IN_STORE'
)

export const fetchSerialPorts = createAction('SYSTEM_SETTINGS/SERIAL_PORTS/FETCH')
export const updateSerialPorts = createAction('SYSTEM_SETTINGS/SERIAL_PORTS/UPDATE')

export const fetchAuthorizationSettings = createAction(
    'SYSTEM_SETTINGS/AUTH_SETTINGS/FETCH'
)
export const updateAuthorizationSettings = createAction(
    'SYSTEM_SETTINGS/AUTH_SETTINGS/UPDATE'
)
export const fetchLicense = createAction('LICENSE/FETCH_INFO')
export const updateLicense = createAction('LICENSE/UPDATE_INFO')

export const fetchServerVersion = createAction('SERVER_INFO/FETCH_INFO')
export const updateServerVersion = createAction('SERVER_INFO/UPDATE_INFO')

export const fetchRotation = createAction('SYSTEM_SETTINGS/ROTATION/FETCH')
export const updateRotation = createAction('SYSTEM_SETTINGS/ROTATION/UPDATE')

export const fetchMFA = createAction('SYSTEM_SETTINGS/MFA/FETCH')
export const updateMFA = createAction('SYSTEM_SETTINGS/MFA/UPDATE')

export const fetchGoogleSettings = createAction('SYSTEM_SETTINGS/GOOGLE_SETTINGS/FETCH')
export const updateGoogleSettings = createAction('SYSTEM_SETTINGS/GOOGLE_SETTINGS/UPDATE')

export const setGoogleAuthFailed = createAction(
    'SYSTEM_SETTINGS/GOOGLE_SETTINGS/GOOGLE_AUTH_FAILED'
)

export const fetchPgmActivationTimeSlot = createAction(
    'SYSTEM_SETTINGS/PGM_ACTIVATION_TIME_SLOT/FETCH'
)
export const updatePgmActivationTimeSlot = createAction(
    'SYSTEM_SETTINGS/PGM_ACTIVATION_TIME_SLOT/UPDATE'
)

export const fetchSelfRegistration = createAction(
    'SYSTEM_SETTINGS/SELF_REGISTRATION/FETCH'
)
export const updateSelfRegistration = createAction(
    'SYSTEM_SETTINGS/SELF_REGISTRATION/UPDATE'
)
