import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'

import {useActions} from 'modules/higherOrder/useActions'
import {sendAddSensorConfirmation} from 'modules/devices/autolearn/actions'
import __ from 'utils/i18n'

export default function ConfirmWithoutPinCodeButtons({session, request, setHasPin}) {
    const isConfirmationWithPinAvailable = !!request?.information?.SETTING_VALUE?.pin_code
    const sensor = request?.sensor
    const [handle] = useActions([sendAddSensorConfirmation])
    const onDismiss = () => handle({session, data: {sensor, isConfirmed: false}})
    const onSubmit = () => handle({session, data: {sensor, pin: '', isConfirmed: true}})

    return !sensor ? null : (
        <div className="autolearn-card-actions-wrapper">
            <div>
                {isConfirmationWithPinAvailable && (
                    <Button onClick={() => setHasPin(true)} flat>
                        {__('+ Add with pin')}
                    </Button>
                )}
            </div>
            <div>
                <Button onClick={onDismiss} flat>
                    {__('Dismiss')}
                </Button>
                <Button primary onClick={onSubmit}>
                    {__('Add Device')}
                </Button>
            </div>
        </div>
    )
}

ConfirmWithoutPinCodeButtons.propTypes = {
    session: PropTypes.object.isRequired,
    request: PropTypes.object.isRequired,
    setHasPin: PropTypes.func.isRequired,
}
