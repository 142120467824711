import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {useActions} from 'modules/higherOrder/useActions'

import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'
import {assign} from 'permissions/installers/actions'
import __ from 'utils/i18n'
import {showAssignInstallerToPanelModal} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'
import useGroup from 'hooks/pages/useGroup'

const ButtonVisibility = compose(withVisibility())(Button)

export default function AssignInstallerButton() {
    const {
        panelId,
        panel: {groupId},
    } = usePanel()
    const {group, isLoading} = useGroup(groupId)
    const {isPermitted} = usePermission(assign)
    const {showAssignInstallerToPanelModal: showAssignInstallerToPanel} = useActions({
        showAssignInstallerToPanelModal,
    })
    const onClick = () =>
        showAssignInstallerToPanel({
            panelId,
        })

    return (
        <>
            {!isLoading && (
                <ButtonVisibility
                    Icon={IconPlus}
                    label={__('Assign Installer')}
                    isVisible={isPermitted && !group?.linkedToDealer}
                    onClick={onClick}
                    className="assignInstallerButton"
                    primary
                />
            )}
        </>
    )
}
