import React from 'react'
import find from 'lodash-es/find'

import {initiate as initiatePermission} from 'permissions/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'

import {ReactComponent as RunIcon} from 'ipmp-react-ui/icons/run.svg'
import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'
import usePermission from 'hooks/usePermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'

const InitiateButtonVisibleConfirmation = withVisibility()(Button)

export function InitiateButtonBase(props) {
    const {isPermitted} = usePermission(initiatePermission)

    return <InitiateButtonVisibleConfirmation isVisible={isPermitted} {...props} />
}

const InitiateButtonConfirmation = withConfirmation(({initiate}) => initiate())(
    InitiateButtonBase
)

export default function InitiateButton() {
    const {rows} = useRemoteInspections()
    const {inspection, initiate, selectedId} = useRemoteInspectionsResult()

    const rri = find(rows, {id: selectedId})
    const isSupported = rri ? rri.isSupported : false

    return (
        <InitiateButtonConfirmation
            initiate={initiate}
            Icon={RunIcon}
            disabled={
                (inspection &&
                    inspection.result === 'progress' &&
                    inspection.progress === 0) ||
                !isSupported
            }
            label={
                inspection &&
                inspection.result === 'progress' &&
                inspection.progress === 0
                    ? __('Pending...')
                    : __('Run')
            }
            title={__('Initiate Inspection')}
            message={__('Do you want to initiate remote inspection now?')}
            positive={__('Begin now')}
            primary
        />
    )
}
