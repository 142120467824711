import React from 'react'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import {
    fetch,
    setQuery,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/reports/list/actions'
import list from 'permissions/reports/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showSaveSearchModal} from 'modules/modals/actions'
import DateFilter from 'components/Search/Filters/DateFilter'
import {selectReportsList} from 'modules/reports/list/selectors'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

const SearchVisibility = withVisibility()(Search)

export default function ReportsSearch({className}) {
    const {scope} = useParams()
    const {isPermitted} = usePermission(list)

    const filters = [
        new ValuesFilter('isActive', __('Is Active'), {
            yes: __('Yes'),
            no: __('No'),
        }),
        new ValuesFilter('isReviewed', __('Is Reviewed'), {
            yes: __('Yes'),
            no: __('No'),
        }),
        new SuggestFilter('unt_serial', __('Panel Id')),
        new SuggestFilter('utg_name', __('Group')),
        new DateFilter('created', __('Created')),
        new DateFilter('finishedAt', __('Last report')),
        new DateFilter('next', __('Next report'), 'future'),
    ].sort((item, next) => item.title.localeCompare(next.title))

    const {filters: selected, query, suggests} = useSelector(selectReportsList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest, onSaveSearch} =
        useActions({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onQuery: setQuery,
            onSuggest: fetchSuggests,
            onSaveSearch: (filters, onClose) =>
                showSaveSearchModal('reports', filters, onClose),
        })

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            onSaveSearch={onSaveSearch}
            key={scope}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Reports')}
        />
    )
}
