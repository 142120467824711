import React from 'react'
import {compose} from 'redux'

import {initiate} from 'permissions/panel/remoteInspections/actions'

import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'

import {ReactComponent as RunIcon} from 'ipmp-react-ui/icons/run.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import usePanelRemoteInspection from 'hooks/pages/usePanelRemoteInspection'

const ButtonVisibleConfirmation = compose(withVisibility(), withConfirmation())(Button)

export default function InitiateNowRemoteInspectionButton() {
    const {isPermitted} = usePermission(initiate)
    const {initiateNow, remoteInspection} = usePanelRemoteInspection()

    return (
        <ButtonVisibleConfirmation
            isVisible={isPermitted}
            Icon={RunIcon}
            title={__('Initiate inspection')}
            label={__('Start Inspection')}
            onClick={initiateNow}
            message={__('Do you want to initiate remote inspection now?')}
            positive={__('Begin Now')}
            disabled={remoteInspection && remoteInspection.progress !== null}
            primary
        />
    )
}
