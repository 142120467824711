import {createAction} from 'redux-actions'

import * as modals from './constants'
import toArray from 'utils/toArray'

export const hide = createAction('MODAL/HIDE', () => null)
export const show = createAction('MODAL/SHOW', (type, args) => ({type, args}))

export function showAboutModal() {
    return show(modals.MODAL_ABOUT)
}

export function showAboutLicenseFeaturesModal() {
    return show(modals.MODAL_ABOUT_LICENSE_FEATURES)
}

export function showAboutReleaseNotesModal() {
    return show(modals.MODAL_ABOUT_RELEASE_NOTES)
}

export function showBlockedNotificationsHelpModal() {
    return show(modals.MODAL_BLOCKED_NOTIFICATIONS_HELP)
}

export function showConfirmationModal(action, messages, outClick = false) {
    action = toArray(action)
    return show(modals.MODAL_CONFIRMATION, {action, messages, outClick})
}

export function showAlert(title, content, close = '') {
    return show(modals.MODAL_ALERT, {title, content, close})
}

export function showAddPanelModal() {
    return show(modals.MODAL_ADD_PANEL)
}

export function showEditPanelInfoModal(panelId) {
    return show(modals.MODAL_EDIT_PANEL_INFO, {panelId})
}

export function showEditPanelCustomerInfoModal(panelId, googleApiError) {
    return show(modals.MODAL_EDIT_PANEL_CUSTOMER_INFO, {panelId, googleApiError})
}

export function showMarkForServiceModal(panelIds) {
    return show(modals.MODAL_MARK_FOR_SERVICE, {panelIds})
}

export function showReassignServiceModal(panelIds) {
    return show(modals.MODAL_REASSIGN_SERVICE, {panelIds})
}

export function showResolveFaultsModal(panelIds) {
    return show(modals.MODAL_FAULTS_RESOLVE, {panelIds})
}

export function showSuspendFaultsModal(panelIds) {
    return show(modals.MODAL_FAULTS_SUSPEND, {panelIds})
}

export function showResumeFaultsModal(panelIds) {
    return show(modals.MODAL_FAULTS_RESUME, {panelIds})
}

export function showChangePanelGroupModal(panelIds) {
    return show(modals.MODAL_CHANGE_PANEL_GROUP, {panelIds})
}

export function showMakeBasicConfigurationModal(panelId) {
    return show(modals.MODAL_MAKE_BASIC_CONFIGURATION, {panelId})
}

export function showPushBasicConfigurationModal(panelIds) {
    return show(modals.MODAL_PUSH_BASIC_CONFIGURATION, {panelIds})
}

export function showExportConfigurationModal(
    configurationId,
    panelId,
    configuration = null
) {
    return show(modals.EXPORT_CONFIGURATION, {configurationId, panelId, configuration})
}

export function showActivatePanel(unitId) {
    return show(modals.MODAL_ACTIVATE_PANEL, {unitId})
}

export function showMasterActivateGUIPanel(unitId) {
    return show(modals.MODAL_ACTIVATE_MASTER_PANEL_GUI, {unitId})
}

export function showAddGroup() {
    return show(modals.MODAL_ADD_GROUP)
}

export function showEditGroup(groupId, activeTab) {
    return show(modals.MODAL_EDIT_GROUP, {groupId, activeTab})
}

export function showEditGroupsNotifications(groupId) {
    return show(modals.MODAL_EDIT_GROUP_NOTIFICATIONS, {groupId})
}

export function showEditGroupsEvents(groupId) {
    return show(modals.MODAL_EDIT_GROUP_EVENTS, {groupId})
}

export function showScheduleRri(panelIds) {
    if (!Array.isArray(panelIds)) {
        panelIds = [panelIds]
    }

    return show(modals.MODAL_SCHEDULE_RRI, {panelIds})
}

export function showCreateReportModal(panelIds, onSuccess) {
    return show(modals.MODAL_CREATE_REPORT, {panelIds, onSuccess})
}

export function showScheduleRriByCsvModal() {
    return show(modals.MODAL_SCHEDULE_RRI_CSV)
}

export function showEditCentralStationLinksModal(groupId, centralStationId, links) {
    return show(modals.MODAL_EDIT_CENTRAL_STATION_LINKS, {
        groupId,
        centralStationId,
        links,
    })
}

export function showCentralStationCreateModal() {
    return show(modals.MODAL_ADD_CENTRAL_STATION)
}

export function showCentralStationEditModal(id) {
    return show(modals.MODAL_EDIT_CENTRAL_STATION, {centralStationId: parseInt(id)})
}

export function showAddUserModal() {
    return show(modals.MODAL_ADD_USER)
}

export function showEditUserModal(userId) {
    return show(modals.MODAL_EDIT_USER, {userId})
}

export function showRemoveUserModal(userIds) {
    return show(modals.MODAL_REMOVE_USER, {userIds})
}

export function showAddDeviceModal(panelId) {
    return show(modals.MODAL_ADD_DEVICE, {panelId})
}

export function showAddCameraModal(panelId) {
    return show(modals.MODAL_ADD_CAMERA, {panelId})
}

export function showRemoteInspectionValuesModal({editable}) {
    return show(modals.MODAL_REMOTE_INSPECTION_VALUES, {editable})
}

export function showEditProfileInfoModal() {
    return show(modals.MODAL_EDIT_PROFILE_INFO)
}

export function showChangePasswordModal() {
    return show(modals.MODAL_CHANGE_PASSWORD)
}

export function showEditSettingsModal() {
    return show(modals.MODAL_EDIT_SETTINGS)
}

export function showRebootPlink(panelId) {
    return show(modals.MODAL_REBOOT_PLINK, {panelId})
}

export function showEnablePlinkLogging(panelId) {
    return show(modals.MODAL_ENABLE_PLINK_LOGGING, {panelId})
}

export function showDisablePlinkLogging(panelId) {
    return show(modals.MODAL_DISABLE_PLINK_LOGGING, {panelId})
}

export function showAddRole() {
    return show(modals.MODAL_ADD_ROLE)
}

export function showEditRole(roleId) {
    return show(modals.MODAL_EDIT_ROLE, {roleId})
}

export function showRemoveRole(roleIds) {
    return show(modals.MODAL_REMOVE_ROLE, {roleIds})
}

export function showEditRolePermission(roleId) {
    return show(modals.MODAL_EDIT_ROLE_PERMISSIONS, {roleId})
}

export function showSaveSearchModal(page, filter, onClose) {
    return show(modals.MODAL_SAVE_SEARCH_SCOPE, {page, filter, onClose})
}

export function showDeviceMeteoChart(panelId, deviceId, type) {
    return show(modals.MODAL_SHOW_DEVICE_METEO_CHART, {panelId, deviceId, type})
}

export function showRenameDeviceModal(panelId, device) {
    return show(modals.MODAL_RENAME_DEVICE, {panelId, device})
}

export function showRenamePartitionModal(panelId, partition) {
    return show(modals.MODAL_RENAME_PARTITION, {panelId, partition})
}

export function showUnavailableFeatureModal({
    message,
    text,
    unavailablePanels,
    availableIds,
    action,
}) {
    return show(modals.MODAL_UNAVAILABLE_FEATURE, {
        message,
        text,
        unavailablePanels,
        availableIds,
        action,
    })
}

export function showFirmwareUpgradeModal(data) {
    return show(modals.MODAL_FIRMWARE_UPGRADE, {...data})
}

export function showFirmwarePatchTagUpdateModal({panelIds, panelId}) {
    return show(modals.MODAL_FIRMWARE_PATCH_TAG_UPDATE, {panelIds, panelId})
}

export function showTriggerDiscoveryModal(panelIds) {
    return show(modals.MODAL_TRIGGER_DISCOVERY, {panelIds})
}

export function showSystemSettingsCellularConnectedModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_CELLULAR_CONNECTED, {...props})
}

export function showSystemSettingsBroadbandConnectedModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_BROADBAND_CONNECTED, {...props})
}

export function showSystemSettingsCommonConnectedModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_COMMON_CONNECTED, {...props})
}

export function showSystemSettingsSupervisionModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_SUPERVISION, {...props})
}

export function showSystemSettingsRemoteInspectionModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_REMOTE_INSPECTION, {...props})
}

export function showSystemSettingsInteractiveSessionModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_INTERACTIVE_SESSION, {...props})
}

export function showSystemSettingsUserNotificationsModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_USER_NOTIFICATIONS, {...props})
}

export function showSystemSettingsAdvertisementModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_ADVERTISEMENT, {...props})
}

export function showSystemSettingsEditMessageBrokerModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER, {...props})
}

export function showSystemSettingsAddMessageBrokerModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER, {...props})
}

export function showSystemSettingsAuthorizationSettingsModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_AUTHORIZATION_SETTINGS, {...props})
}

export function showSystemSettingsRotationModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_ROTATION, {...props})
}

export function showSystemSettingsMFAGeneralModal() {
    return show(modals.MODAL_SYSTEM_SETTINGS_MFA_GENERAL)
}

export function showSystemSettingsMFAParametersModal() {
    return show(modals.MODAL_SYSTEM_SETTINGS_MFA_PARAMETERS)
}

export function showSystemSettingsGoogleModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_GOOGLE, {...props})
}

export function showSystemSettingsPgmActivationTimeSlotModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_PGM_ACTIVATION_TIME_SLOT, {...props})
}

export function showSystemSettingsSelfRegistrationModal(props) {
    return show(modals.MODAL_SYSTEM_SETTINGS_SELF_REGISTRATION, {...props})
}

export function showAssignUserToPanelModal(props) {
    return show(modals.MODAL_ASSIGN_USER_TO_PANEL, {...props})
}

export function showRegisterInteractiveUserModal() {
    return show(modals.MODAL_REGISTER_INTERACTIVE_USER)
}

export function showSetUserPinModal({panelId, panelUserId}) {
    return show(modals.MODAL_SET_USER_PIN, {panelId, panelUserId})
}

export function showSetPanelUserLabelModal({panelId, panelUserId, userLabel}) {
    return show(modals.MODAL_SET_PANEL_USER_LABEL, {panelId, panelUserId, userLabel})
}

export function showSetUserPartitionsModal({panelId, panelUserId, partitions}) {
    return show(modals.MODAL_SET_PANEL_USER_PARTITIONS, {
        panelId,
        panelUserId,
        partitions,
    })
}

export function showSetUserLastNameModal({panelId, panelUserId, lastName}) {
    return show(modals.MODAL_SET_PANEL_USER_LAST_NAME, {panelId, panelUserId, lastName})
}

export function showSetUserExpirationDateModal({panelId, panelUserId, expirationDate}) {
    return show(modals.MODAL_SET_PANEL_USER_EXPIRATION_DATE, {
        panelId,
        panelUserId,
        expirationDate,
    })
}

export function showAssignInstallerToPanelModal(props) {
    return show(modals.MODAL_ASSIGN_INSTALLER_TO_PANEL, {...props})
}

export function showAddInstallerModal() {
    return show(modals.MODAL_REGISTER_INSTALLER)
}

export function showAddZoneTypeModal() {
    return show(modals.MODAL_ZONE_TYPE_ADD)
}

export function showEditZoneTypeModal(zoneTypeId) {
    return show(modals.MODAL_ZONE_TYPE_EDIT, {zoneTypeId})
}

export function showAddNotificationTypeModal() {
    return show(modals.MODAL_NOTIFICATION_TYPE_ADD)
}

export function showEditNotificationTypeModal(notificationTypeId) {
    return show(modals.MODAL_NOTIFICATION_TYPE_EDIT, {notificationTypeId})
}

export function showAddEventTypeModal() {
    return show(modals.MODAL_EVENT_TYPE_ADD)
}

export function showEditEventTypeModal(eventTypeId) {
    return show(modals.MODAL_EVENT_TYPE_EDIT, {eventTypeId})
}

export function showAddStateTypeModal() {
    return show(modals.MODAL_STATE_TYPE_ADD)
}

export function showEditStateTypeModal(stateTypeId) {
    return show(modals.MODAL_STATE_TYPE_EDIT, {stateTypeId})
}

export function showAddEventRuleModal() {
    return show(modals.MODAL_EVENT_RULE_ADD, {combinedModals: true})
}

export function showEditEventRuleModal(eventRuleId) {
    return show(modals.MODAL_EVENT_RULE_EDIT, {combinedModals: true, eventRuleId})
}

export function showChangePgmStateParametersModal(payload) {
    return show(modals.MODAL_CHANGE_PGM_STATE_PARAMS, payload)
}

export function showAddPgmModal() {
    return show(modals.MODAL_ADD_PGM)
}

export function showChangeInstallerCodeModal(panelId, featuresInstaller) {
    return show(modals.MODAL_CHANGE_INSTALLER_CODE, {panelId, featuresInstaller})
}

export function showEventInitiate(panelId, eventType) {
    return show(modals.MODAL_EVENT_INITIATE, {panelId, eventType})
}

export function showEditHadIconModal({panelId, hadId, deviceType, defaultValue}) {
    return show(modals.MODAL_HAD_EDIT_ICON, {panelId, hadId, deviceType, defaultValue})
}

export function showEditHadLabelModal({panelId, hadId, deviceType, defaultValue}) {
    return show(modals.MODAL_HAD_EDIT_LABEL, {panelId, deviceType, hadId, defaultValue})
}

export function showDscConnectionFormModal(panelId) {
    return show(modals.MODAL_DLS_CONNECT_DSC, {panelId})
}

export function showSmartCommunicatorConnectionFormModal(panelId) {
    return show(modals.MODAL_DLS_CONNECT_SMART_COMMUNICATOR, {panelId})
}

export function showPanelBillingActivateModal({panelId, planId}) {
    return show(modals.MODAL_PANEL_BILLING_ACTIVATE, {panelId, planId})
}

export function showConvertInstallerModal(installer) {
    return show(modals.MODAL_CONVERT_INSTALLER, {installer})
}
