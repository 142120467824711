import React from 'react'
import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import InfoCards, {InfoCard} from 'ipmp-react-ui/InfoCards'
import Button from 'ipmp-react-ui/Button'
import Disclaimer from 'ipmp-react-ui/Disclaimer'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import withLoader from 'containers/withLoader'
import __ from 'utils/i18n'
import * as actions from 'modules/system/settings/actions'
import {
    showSystemSettingsMFAGeneralModal,
    showSystemSettingsMFAParametersModal,
    showSystemSettingsRemoteInspectionModal,
    showSystemSettingsRotationModal,
    showSystemSettingsGoogleModal,
    showSystemSettingsPgmActivationTimeSlotModal,
    showSystemSettingsSelfRegistrationModal,
} from 'modules/modals/actions'
import {selectLicense} from 'modules/system/settings/selectors'
import {isBillingPlusEnabled} from 'constants/billingPlan'

import RemoteInspection from './RemoteInspection'
import Rotation from './Rotation'
import MFAParameters from './MFAParameters'
import MFAGeneral from './MFAGeneral'
import GoogleSettings from './GoogleSettings'
import PgmActivationTimeSlot from './PgmActivationTimeSlot'
import SelfRegistration from './SelfRegistration'

function Resolve({
    remoteInspectionModal,
    rotationModal,
    showSystemSettingsMFAParametersModal,
    showSystemSettingsMFAGeneralModal,
    showSystemSettingsGoogleModal,
    showSystemSettingsPgmActivationTimeSlotModal,
    showSystemSettingsSelfRegistrationModal,
}) {
    const {license} = useSelector(selectLicense)

    const remoteInspectionBtn = (
        <Button onClick={remoteInspectionModal} borderless Icon={IconEdit} />
    )
    const rotationBtn = <Button onClick={rotationModal} borderless Icon={IconEdit} />
    const mfaGeneralBtn = (
        <Button onClick={showSystemSettingsMFAGeneralModal} borderless Icon={IconEdit} />
    )
    const pgmBtn = (
        <Button
            onClick={showSystemSettingsPgmActivationTimeSlotModal}
            borderless
            Icon={IconEdit}
        />
    )
    const googleSettingsBtn = (
        <Button onClick={showSystemSettingsGoogleModal} borderless Icon={IconEdit} />
    )
    const mfaParametersBtn = (
        <Button
            onClick={showSystemSettingsMFAParametersModal}
            borderless
            Icon={IconEdit}
        />
    )
    const selfRegistrationBtn = (
        <Button
            onClick={showSystemSettingsSelfRegistrationModal}
            borderless
            Icon={IconEdit}
        />
    )

    return (
        <InfoCards cols={2} className="resolveSettings">
            <InfoCard
                title={__('Remote Inspection')}
                headerButton={remoteInspectionBtn}
                cols={2}
            >
                <RemoteInspection />
            </InfoCard>
            <InfoCard title={__('Rotation')} headerButton={rotationBtn} cols={2}>
                <Rotation />
            </InfoCard>
            <InfoCard
                title={__('Automation activation settings')}
                headerButton={pgmBtn}
                cols={2}
            >
                <PgmActivationTimeSlot />
                <Disclaimer>
                    {__(
                        'Enable this feature to make possible an activation of Automation devices, Outputs or PGMs on a panel during set time window when alarm is received. It allows to avoid false activation.'
                    )}
                </Disclaimer>
            </InfoCard>
            <InfoCard
                title={__('Google Settings')}
                headerButton={googleSettingsBtn}
                cols={2}
            >
                <GoogleSettings />
                <Disclaimer>
                    {__(
                        'When Google API key is set you can use location features as panel location displaying'
                    )}
                </Disclaimer>
            </InfoCard>
            <InfoCard
                title={__('Multi-Factor Authentication General')}
                headerButton={mfaGeneralBtn}
                cols={2}
            >
                <MFAGeneral />
            </InfoCard>
            <InfoCard
                title={__('Multi-Factor Authentication Parameters')}
                headerButton={mfaParametersBtn}
                cols={2}
                twoRows
            >
                <MFAParameters />
            </InfoCard>
            {isBillingPlusEnabled(license) && (
                <InfoCard
                    title={__('Self-Registration')}
                    headerButton={selfRegistrationBtn}
                    cols={2}
                >
                    <SelfRegistration />
                </InfoCard>
            )}
        </InfoCards>
    )
}

Resolve.propTypes = {
    rotationModal: PropTypes.func.isRequired,
    remoteInspectionModal: PropTypes.func.isRequired,
    showSystemSettingsGoogleModal: PropTypes.func.isRequired,
    showSystemSettingsPgmActivationTimeSlotModal: PropTypes.func.isRequired,
    showSystemSettingsMFAGeneralModal: PropTypes.func.isRequired,
    showSystemSettingsMFAParametersModal: PropTypes.func.isRequired,
    showSystemSettingsSelfRegistrationModal: PropTypes.func.isRequired,
}

export default compose(
    connect(
        ({
            system: {
                settings: {
                    remoteInspection,
                    rotation,
                    mfa,
                    messageBrokers,
                    googleSettings,
                    pgmActivationTimeSlot,
                },
            },
        }) => ({
            remoteInspection,
            rotation,
            googleSettings,
            pgmActivationTimeSlot,
            isLoading:
                remoteInspection.isLoading ||
                rotation.isLoading ||
                mfa.isLoading ||
                messageBrokers.isLoading ||
                googleSettings.isLoading ||
                pgmActivationTimeSlot.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    remoteInspection: actions.fetchRemoteInspection,
                    rotation: actions.fetchRotation,
                    googleSettings: actions.fetchGoogleSettings,
                    pgmActivationTimeSlot: actions.fetchPgmActivationTimeSlot,
                    fetchMFA: actions.fetchMFA,
                    fetchMessageBrokers: actions.fetchMessageBrokers,
                    selfRegistration: actions.fetchSelfRegistration,
                    showSystemSettingsRemoteInspectionModal,
                    showSystemSettingsRotationModal,
                    showSystemSettingsMFAGeneralModal,
                    showSystemSettingsMFAParametersModal,
                    showSystemSettingsGoogleModal,
                    showSystemSettingsPgmActivationTimeSlotModal,
                    showSystemSettingsSelfRegistrationModal,
                },
                dispatch
            ),
        (stateProps, dispatchProps) => {
            return {
                ...stateProps,
                ...dispatchProps,
                remoteInspectionModal: () =>
                    dispatchProps.showSystemSettingsRemoteInspectionModal(
                        stateProps.remoteInspection
                    ),
                rotationModal: () =>
                    dispatchProps.showSystemSettingsRotationModal(stateProps.rotation),
                showSystemSettingsGoogleModal: () =>
                    dispatchProps.showSystemSettingsGoogleModal(
                        stateProps.googleSettings
                    ),
                showSystemSettingsPgmActivationTimeSlotModal: () =>
                    dispatchProps.showSystemSettingsPgmActivationTimeSlotModal(
                        stateProps.pgmActivationTimeSlot
                    ),
                showSystemSettingsSelfRegistrationModal: () =>
                    dispatchProps.showSystemSettingsSelfRegistrationModal(
                        stateProps.pgmActivationTimeSlot
                    ),
            }
        }
    ),
    withLoader(
        ({
            remoteInspection,
            googleSettings,
            pgmActivationTimeSlot,
            rotation,
            fetchMessageBrokers,
            fetchMFA,
            selfRegistration,
        }) => {
            remoteInspection()
            googleSettings()
            pgmActivationTimeSlot()
            rotation()
            fetchMessageBrokers()
            fetchMFA()
            selfRegistration()
        }
    )
)(Resolve)
