import React from 'react'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import useForm from '../useForm'
import {useActions} from 'modules/higherOrder/useActions'
import useConfirmationModal from '../useConfirmationModal'
import {panelBillingActivate} from 'modules/forms/handlers'
import {selectPlan} from 'modules/panels/billingPlan/selectors'
import {showPanelBillingActivateModal} from 'modules/modals/actions'

const useBilling = (panelId) => {
    const showModal = useActions(
        (planId) =>
            showPanelBillingActivateModal({
                planId,
                panelId,
            }),
        [panelId]
    )

    const {handle: activateHandle} = useForm(panelBillingActivate)

    const {onClick: reactivatePlan} = useConfirmationModal(
        ({activateHandle, panelId}, {planId, countryId}) =>
            () =>
                activateHandle({countryId, panelId, planId}),
        () => ({
            title: __('Billing plan reactivation'),
            message: (
                <>
                    {__('You are about to reactive billing plan for the panel.')} <br />
                    {__('Do you want to proceed?')}
                </>
            ),
            positive: __('Reactivate'),
        }),
        {activateHandle, panelId}
    )

    return {
        showModal,
        reactivatePlan,
        selectPlan: (planId) =>
            useSelector((state) => selectPlan(state, panelId, planId)),
    }
}

export default useBilling
