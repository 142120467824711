import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {useActions} from 'modules/higherOrder/useActions'
import {showEditStateTypeModal} from 'modules/modals/actions'

export default function EditStateType({row: {id}}) {
    const onClick = useActions(showEditStateTypeModal)
    const onClickById = () => onClick(id)

    return <Button flat small Icon={IconEdit} onClick={onClickById} />
}
