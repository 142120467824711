export const MODAL_ABOUT = 'ABOUT'
export const MODAL_ABOUT_LICENSE_FEATURES = 'ABOUT_LICENSE_FEATURES'
export const MODAL_ABOUT_RELEASE_NOTES = 'ABOUT_RELEASE_NOTES'
export const MODAL_BLOCKED_NOTIFICATIONS_HELP = 'BLOCKED_NOTIFICATIONS_HELP'

export const MODAL_CONFIRMATION = 'CONFIRMATION'
export const MODAL_ALERT = 'ALERT'

export const MODAL_MARK_FOR_SERVICE = 'MARK_FOR_SERVICE'
export const MODAL_REASSIGN_SERVICE = 'MODAL_REASSIGN_SERVICE'
export const MODAL_CHANGE_PANEL_GROUP = 'CHANGE_GROUP'
export const MODAL_FAULTS_RESOLVE = 'FAULTS_RESOLVE'
export const MODAL_FAULTS_SUSPEND = 'FAULTS_SUSPEND'
export const MODAL_FAULTS_RESUME = 'FAULTS_RESUME'

export const MODAL_ADD_PANEL = 'ADD_PANEL'
export const MODAL_EDIT_PANEL_INFO = 'EDIT_PANEL_INFO'
export const MODAL_EDIT_PANEL_CUSTOMER_INFO = 'EDIT_PANEL_CUSTOMER_INFO'
export const MODAL_MAKE_BASIC_CONFIGURATION = 'CREATE_BASIC_CONFIGURATION'
export const EXPORT_CONFIGURATION = 'EXPORT_CONFIGURATION'
export const MODAL_PUSH_BASIC_CONFIGURATION = 'PUSH_BASIC_CONFIGURATION'
export const MODAL_ACTIVATE_PANEL = 'ACTIVATE_PANEL'
export const MODAL_ACTIVATE_MASTER_PANEL_GUI = 'ACTIVATE_MASTER_PANEL_GUI'

export const MODAL_ADD_GROUP = 'ADD_GROUP'
export const MODAL_EDIT_GROUP = 'EDIT_GROUP'
export const MODAL_EDIT_GROUP_NOTIFICATIONS = 'MODAL_EDIT_GROUP_NOTIFICATIONS'
export const MODAL_EDIT_GROUP_EVENTS = 'MODAL_EDIT_GROUP_EVENTS'

export const MODAL_EDIT_CENTRAL_STATION_LINKS = 'EDIT_CENTRAL_STATION_LINKS'
export const MODAL_ADD_CENTRAL_STATION = 'ADD_CENTRAL_STATION'
export const MODAL_EDIT_CENTRAL_STATION = 'EDIT_CENTRAL_STATION'

export const MODAL_SCHEDULE_RRI = 'SCHEDULE_RRI'
export const MODAL_SCHEDULE_RRI_CSV = 'SCHEDULE_RRI_CSV'

export const MODAL_CREATE_REPORT = 'CREATE_REPORT'

export const MODAL_REMOTE_INSPECTION_VALUES = 'REMOTE_INSPECTION_VALUES'

export const MODAL_ADD_USER = 'ADD_USER'
export const MODAL_EDIT_USER = 'EDIT_USER'
export const MODAL_REMOVE_USER = 'REMOVE_USER'

export const MODAL_ADD_DEVICE = 'ADD_DEVICE'
export const MODAL_ADD_CAMERA = 'ADD_CAMERA'
export const MODAL_SHOW_DEVICE_METEO_CHART = 'SHOW_DEVICE_METEO_CHART'
export const MODAL_RENAME_PARTITION = 'MODAL_RENAME_PARTITION'
export const MODAL_RENAME_DEVICE = 'MODAL_RENAME_DEVICE'

export const MODAL_EDIT_PROFILE_INFO = 'EDIT_PROFILE_INFO'
export const MODAL_EDIT_SETTINGS = 'MODAL_EDIT_SETTINGS'
export const MODAL_CHANGE_PASSWORD = 'CHANGE_PASSWORD'

export const MODAL_REBOOT_PLINK = 'REBOOT_PLINK'
export const MODAL_ENABLE_PLINK_LOGGING = 'ENABLE_PLINK_LOGGING'
export const MODAL_DISABLE_PLINK_LOGGING = 'DISABLE_PLINK_LOGGING'

export const MODAL_ADD_ROLE = 'ADD_ROLE'
export const MODAL_EDIT_ROLE = 'EDIT_ROLE'
export const MODAL_REMOVE_ROLE = 'REMOVE_ROLE'
export const MODAL_EDIT_ROLE_PERMISSIONS = 'EDIT_ROLE_PERMISSIONS'

export const MODAL_SAVE_SEARCH_SCOPE = 'SAVE_SEARCH_SCOPE'

export const MODAL_UNAVAILABLE_FEATURE = 'UNAVAILABLE_FEATURE'

export const MODAL_FIRMWARE_UPGRADE = 'FIRMWARE_UPGRADE'
export const MODAL_FIRMWARE_PATCH_TAG_UPDATE = 'FIRMWARE_PATCH_TAG_UPDATE'

export const MODAL_TRIGGER_DISCOVERY = 'TRIGGER_DISCOVERY'

export const MODAL_SYSTEM_SETTINGS_CELLULAR_CONNECTED =
    'SYSTEM_SETTINGS_CELLULAR_CONNECTED'
export const MODAL_SYSTEM_SETTINGS_BROADBAND_CONNECTED =
    'SYSTEM_SETTINGS_BROADBAND_CONNECTED'
export const MODAL_SYSTEM_SETTINGS_COMMON_CONNECTED = 'SYSTEM_SETTINGS_COMMON_CONNECTED'
export const MODAL_SYSTEM_SETTINGS_SUPERVISION = 'SYSTEM_SETTINGS_SUPERVISION'
export const MODAL_SYSTEM_SETTINGS_REMOTE_INSPECTION = 'SYSTEM_SETTINGS_REMOTE_INSPECTION'
export const MODAL_SYSTEM_SETTINGS_INTERACTIVE_SESSION =
    'SYSTEM_SETTINGS_INTERACTIVE_SESSION'
export const MODAL_SYSTEM_SETTINGS_USER_NOTIFICATIONS =
    'SYSTEM_SETTINGS_USER_NOTIFICATIONS'
export const MODAL_SYSTEM_SETTINGS_ADVERTISEMENT = 'SYSTEM_SETTINGS_ADVERTISEMENT'
export const MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER = 'SYSTEM_SETTINGS_MESSAGE_BROKER'
export const MODAL_SYSTEM_SETTINGS_AUTHORIZATION_SETTINGS =
    'SYSTEM_SETTINGS_AUTHORIZATION_SETTINGS'
export const MODAL_SYSTEM_SETTINGS_ROTATION = 'SYSTEM_SETTINGS_ROTATION'
export const MODAL_SYSTEM_SETTINGS_MFA_GENERAL = 'SYSTEM_SETTINGS_MFA_GENERAL'
export const MODAL_SYSTEM_SETTINGS_MFA_PARAMETERS = 'SYSTEM_SETTINGS_MFA_PARAMETERS'
export const MODAL_SYSTEM_SETTINGS_GOOGLE = 'SYSTEM_SETTINGS_GOOGLE'
export const MODAL_SYSTEM_SETTINGS_PGM_ACTIVATION_TIME_SLOT =
    'SYSTEM_SETTINGS_PGM_ACTIVATION_TIME_SLOT'
export const MODAL_SYSTEM_SETTINGS_SELF_REGISTRATION = 'SYSTEM_SETTINGS_SELF_REGISTRATION'

export const MODAL_ASSIGN_USER_TO_PANEL = 'ASSIGN_USER_TO_PANEL'
export const MODAL_REGISTER_INTERACTIVE_USER = 'REGISTER_INTERACTIVE_USER'
export const MODAL_SET_USER_PIN = 'SET_USER_PIN'
export const MODAL_SET_PANEL_USER_LABEL = 'SET_PANEL_USER_LABEL'
export const MODAL_SET_PANEL_USER_PARTITIONS = 'SET_PANEL_USER_PARTITIONS'
export const MODAL_SET_PANEL_USER_LAST_NAME = 'SET_PANEL_USER_LAST_NAME'
export const MODAL_SET_PANEL_USER_EXPIRATION_DATE = 'SET_PANEL_USER_EXPIRATION_DATE'

export const MODAL_ASSIGN_INSTALLER_TO_PANEL = 'ASSIGN_INSTALLER_TO_PANEL'
export const MODAL_REGISTER_INSTALLER = 'REGISTER_INSTALLER'
export const MODAL_CHANGE_INSTALLER_CODE = 'CHANGE_INSTALLER_CODE'

export const MODAL_ZONE_TYPE_ADD = 'ZONE_TYPE_ADD'
export const MODAL_ZONE_TYPE_EDIT = 'ZONE_TYPE_EDIT'

export const MODAL_NOTIFICATION_TYPE_ADD = 'NOTIFICATION_TYPE_ADD'
export const MODAL_NOTIFICATION_TYPE_EDIT = 'NOTIFICATION_TYPE_EDIT'

export const MODAL_EVENT_TYPE_ADD = 'EVENT_TYPE_ADD'
export const MODAL_EVENT_TYPE_EDIT = 'EVENT_TYPE_EDIT'

export const MODAL_STATE_TYPE_ADD = 'STATE_TYPE_ADD'
export const MODAL_STATE_TYPE_EDIT = 'STATE_TYPE_EDIT'

export const MODAL_EVENT_RULE_ADD = 'EVENT_RULE_ADD'
export const MODAL_EVENT_RULE_EDIT = 'EVENT_RULE_EDIT'

export const MODAL_CHANGE_PGM_STATE_PARAMS = 'CHANGE_PGM_STATE_PARAMS'
export const MODAL_ADD_PGM = 'ADD_PGM'

export const MODAL_EVENT_INITIATE = 'EVENT_INITIATE'

export const MODAL_HAD_EDIT_LABEL = 'EDIT_LABEL'
export const MODAL_HAD_EDIT_ICON = 'EDIT_ICON'

export const MODAL_DLS_CONNECT_DSC = 'DLS_CONNECT_DSC'
export const MODAL_DLS_CONNECT_SMART_COMMUNICATOR = 'DLS_CONNECT_SMART_COMMUNICATOR'

export const MODAL_PANEL_BILLING_ACTIVATE = 'PANEL_BILLING_ACTIVATE'

export const MODAL_CONVERT_INSTALLER = 'CONVERT_INSTALLER'
