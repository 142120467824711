import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {add} from 'permissions/installers/actions'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showAddInstallerModal} from 'modules/modals/actions'

const ButtonVisibility = withVisibility()(Button)

export default function AddInstaller() {
    const {isPermitted} = usePermission(add)
    const {onClick} = useActions({
        onClick: showAddInstallerModal,
    })

    return (
        <ButtonVisibility
            label={__('Add Installer')}
            Icon={IconPlus}
            onClick={onClick}
            isVisible={isPermitted}
            primary
        />
    )
}
