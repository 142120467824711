import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {useActions} from 'modules/higherOrder/useActions'
import {showEditZoneTypeModal} from 'modules/modals/actions'

export default function EditZoneType({row: {id}}) {
    const {onClick} = useActions(
        {
            onClick: () => showEditZoneTypeModal(id),
        },
        [id]
    )

    return <Button flat small Icon={IconEdit} onClick={onClick} />
}
