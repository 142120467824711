import PanelsSearch from 'pages/Panels/PanelsSearch'
import EventsSearch from 'pages/Events/List/EventsSearch'
import PanelEventsSearch from 'pages/Panel/Events/PanelEventsSearch'
import RemoteInspectionSearch from 'pages/RemoteInspection/RemoteInspectionSearch'
import BatchesSearch from 'pages/Batches/BatchesSearch'
import ReportsSearch from 'pages/Reports/ReportsSearch'
import GroupsSearch from 'pages/Groups/GroupsSearch'
import StationsSearch from 'pages/Group/Stations/StationsSearch'
import UsersSearch from 'pages/Users/UsersSearch'
import RolesSearch from 'pages/Roles/RolesSearch'
import CentralStationsSearch from 'pages/CentralStations/CentralStationsSearch'
import CommunicationSearch from 'pages/CentralStation/Communication/Bar/CommunicationSearch'
import ProcessesSearch from 'pages/Processes/ProcessesSearch'
import ActionLogSearch from 'pages/ActionLog/ActionLogSearch'
import BasicConfigurationsSearch from 'pages/BasicConfigurations/BasicConfigurationsSearch'
import InteractiveUsersSearch from 'pages/InteractiveUsers/InteractiveUsersSearch'
import InstallersSearch from 'pages/Installers/InstallersSearch'
import FirmwareSearch from 'pages/Firmware/FirmwareSearch'
import FirmwareUpgradeSearch from 'pages/Firmware/Upgrade/FirmwareUpgradeSearch'
import FirmwarePatchTagUpdateSearch from 'pages/Firmware/PatchTagUpdate/FirmwarePatchTagUpdateSearch'
import {default as BatchesRunnersSearch} from 'pages/Batches/Runners/RunnersSearch'
import EventRulesSearch from 'pages/Settings/EventRules/EventRulesSearch'
import PanelInteractiveUsersSearch from 'pages/Panel/PanelInteractiveUsers/PanelInteractiveUsersSearch'

export const ACCOUNT_ROUTE = '/accounts'
export const INSTALLERS_ROUTE = '/installers'
export const PANELS_ROUTE = '/panels/?.*$'
export const PANEL_INTERACTIVE_USERS_ROUTE = '/panel/[0-9]+/interactive-users'
export const EVENTS_ROUTE = '/events/?.*$'
export const REMOTE_INSPECTIONS_ROUTE = '/remote-inspections/?.*$'
export const BATCHES_ROUTE = '/batches'
export const BATCHES_RUNNERS_ROUTE = '/batches/?.*/runners$'
export const REPORTS_ROUTE = '/reports/?.*$'
export const FIRMWARE_ROUTE = '/firmware$'
export const FIRMWARE_UPGRADE_ROUTE = '/firmware/upgrade/[0-9]+/[0-9]+'
export const FIRMWARE_PATCH_TAG_ROUTE = '/firmware/patch-tag-update'
export const SYSTEM_GROUPS_ROUTE = '/groups'
export const SYSTEM_GROUP_ROUTE = '/groups/[0-9]+/stations'
export const SYSTEM_USERS_ROUTE = '/users'
export const SYSTEM_ROLES_ROUTE = '/roles'
export const SYSTEM_BASIC_CONFIGURATIONS_ROUTE = '/basic-configurations'
export const SYSTEM_CENTRAL_STATION_COMMUNICATION_ROUTE =
    '/central-stations/[0-9]+/communication'
export const SYSTEM_CENTRAL_STATIONS_ROUTE = '/central-stations'
export const SYSTEM_PROCESSES_ROUTE = '/processes'
export const SYSTEM_ACTION_LOG_ROUTE = '/action-log'
export const EVENT_RULES_ROUTE = '/settings/event-rules'
export const PANEL_EVENTS_ROUTE = '/panel/[0-9]+/events'

export const routesMap = {
    [INSTALLERS_ROUTE]: InstallersSearch,
    [ACCOUNT_ROUTE]: InteractiveUsersSearch,
    [PANELS_ROUTE]: PanelsSearch,
    [PANEL_INTERACTIVE_USERS_ROUTE]: PanelInteractiveUsersSearch,
    [PANEL_EVENTS_ROUTE]: PanelEventsSearch,
    [EVENTS_ROUTE]: EventsSearch,
    [REMOTE_INSPECTIONS_ROUTE]: RemoteInspectionSearch,
    [BATCHES_RUNNERS_ROUTE]: BatchesRunnersSearch,
    [BATCHES_ROUTE]: BatchesSearch,
    [REPORTS_ROUTE]: ReportsSearch,
    [FIRMWARE_ROUTE]: FirmwareSearch,
    [FIRMWARE_UPGRADE_ROUTE]: FirmwareUpgradeSearch,
    [FIRMWARE_PATCH_TAG_ROUTE]: FirmwarePatchTagUpdateSearch,
    [SYSTEM_GROUPS_ROUTE]: GroupsSearch,
    [SYSTEM_GROUP_ROUTE]: StationsSearch,
    [SYSTEM_USERS_ROUTE]: UsersSearch,
    [SYSTEM_ROLES_ROUTE]: RolesSearch,
    [SYSTEM_BASIC_CONFIGURATIONS_ROUTE]: BasicConfigurationsSearch,
    [SYSTEM_CENTRAL_STATION_COMMUNICATION_ROUTE]: CommunicationSearch,
    [SYSTEM_CENTRAL_STATIONS_ROUTE]: CentralStationsSearch,
    [SYSTEM_PROCESSES_ROUTE]: ProcessesSearch,
    [SYSTEM_ACTION_LOG_ROUTE]: ActionLogSearch,
    [EVENT_RULES_ROUTE]: EventRulesSearch,
}
