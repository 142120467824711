import {put, call} from 'redux-saga/effects'
import {getSelfRegistration} from 'api/system/settings'

import * as actions from 'modules/system/settings/actions'
import {snackShow} from 'modules/snacks'
import {ACTION_NOT_SUPPORTED} from 'constants/errorType'

export default function* watchSelfRegistration() {
    try {
        const data = yield call(getSelfRegistration)

        yield put(actions.updateSelfRegistration(data))
    } catch (error) {
        if (error.reason !== ACTION_NOT_SUPPORTED) {
            yield put(snackShow(error.message))
        }
    }
}
