import {humanDuration} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const PROCESS_ALREADY_ENQUEUED = 'PROCESS_ALREADY_ENQUEUED'
export const PROCESS_FAILED_TO_ENQUEUE = 'PROCESS_FAILED_TO_ENQUEUE'
export const ENDPOINT_NOT_FOUND = 'ENDPOINT_NOT_FOUND'
export const SCOPE_NOT_FOUND = 'SCOPE_NOT_FOUND'
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const AUTHENTICATION_BLOCKED = 'AUTHENTICATION_BLOCKED'
export const AUTHENTICATION_SEND_OTP_BLOCKED = 'AUTHENTICATION_SEND_OTP_BLOCKED'
export const AUTHENTICATION_CONFIRM_OTP_BLOCKED = 'AUTHENTICATION_CONFIRM_OTP_BLOCKED'
export const AUTHENTICATION_SESSION_BLOCKED = 'AUTHENTICATION_SESSION_BLOCKED'
export const AUTHENTICATION_SEND_SMS_DELAYED = 'AUTHENTICATION_SEND_SMS_DELAYED'
export const AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED'
export const METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED'
export const DENIED = 'DENIED'
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const LICENSE_LIMIT_REACHED = 'LICENSE_LIMIT_REACHED'
export const PARAMS_MISSING = 'PARAMS_MISSING'
export const BAD_REQUEST_PARAMS = 'BAD_REQUEST_PARAMS'
export const BAD_REQUEST = 'BAD_REQUEST'
export const ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND'
export const SERVICE_ERROR = 'SERVICE_ERROR'
export const PANEL_IS_NOT_CONNECTED = 'PANEL_IS_NOT_CONNECTED'
export const PANEL_IS_CONNECTED = 'PANEL_IS_CONNECTED'
export const PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED'
export const PROCESS_NOT_FOUND = 'PROCESS_NOT_FOUND'
export const CUSTOM_ERROR = 'CUSTOM_ERROR'
export const INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE'
export const NOT_ALLOWED_FOR_CURRENT_USER = 'NOT_ALLOWED_FOR_CURRENT_USER'
export const EVENT_RULE_ALREADY_EXIST = 'EVENT_RULE_ALREADY_EXIST'
export const ZONE_TYPE_ALREADY_EXIST = 'ZONE_TYPE_ALREADY_EXIST'
export const NOTIFICATION_TYPE_ALREADY_EXIST = 'NOTIFICATION_TYPE_ALREADY_EXIST'
export const STATE_TYPE_ALREADY_EXIST = 'STATE_TYPE_ALREADY_EXIST'
export const EVENT_TYPE_ALREADY_EXIST = 'EVENT_TYPE_ALREADY_EXIST'
export const USER_UNIT_ACCESS_DENIED = 'USER_UNIT_ACCESS_DENIED'
export const ACTION_NOT_SUPPORTED = 'ACTION_NOT_SUPPORTED'

export function messageByError(errorType, details = null) {
    switch (errorType) {
        case PROCESS_ALREADY_ENQUEUED:
            return __('Process already enqueued')
        case PROCESS_FAILED_TO_ENQUEUE:
            return __('Failed to enqueue process')
        case ENDPOINT_NOT_FOUND:
            return __('The requested page could not be found')
        case SCOPE_NOT_FOUND:
            return __('Search scope not exists')
        case AUTHENTICATION_FAILED:
            return __('Authentication failed')
        case AUTHENTICATION_BLOCKED:
            return getBlockedMessage(
                __('Limit of entering incorrect credentials is reached.'),
                details?.cooldown
            )
        case AUTHENTICATION_SEND_OTP_BLOCKED:
            return getBlockedMessage(
                __('Limit of requesting SMS codes is reached.'),
                details?.cooldown
            )
        case AUTHENTICATION_CONFIRM_OTP_BLOCKED:
            return getBlockedMessage(
                __('Limit of entering incorrect code is reached.'),
                details?.cooldown
            )
        case AUTHENTICATION_SESSION_BLOCKED:
            return getBlockedMessage(__('Auth sessions limit reach'), details?.cooldown)
        case AUTHENTICATION_SEND_SMS_DELAYED:
            return __('You can not request code at this moment. Please try again later.')
        case AUTHENTICATION_REQUIRED:
            return __('Authentication required')
        case METHOD_NOT_ALLOWED:
            return __('Method not allowed')
        case DENIED:
            return __('Access denied')
        case USER_UNIT_ACCESS_DENIED:
            return __("Installer's access is not approved by customer")
        case INTERNAL_SERVER_ERROR:
            return __('Internal Server Error')
        case LICENSE_LIMIT_REACHED:
            return __('License limit reached')
        case PARAMS_MISSING:
            return __('Params missing')
        case BAD_REQUEST_PARAMS:
            return __('Bad request params')
        case ENTITY_NOT_FOUND:
            return __('Entity not found')
        case SERVICE_ERROR:
            return __('Service Error')
        case PANEL_IS_NOT_CONNECTED:
            return __('Panel is not connected')
        case PANEL_IS_CONNECTED:
            return __('Panel is connected')
        case PROCESS_NOT_FOUND:
            return __('Process not found')
        case PARTIALLY_PROCESSED:
            return __('Partially processed')
        case INVALID_ACCESS_CODE:
            return __('Invalid access code')
        case NOT_ALLOWED_FOR_CURRENT_USER:
            return __('Not allowed for current user')
        case EVENT_RULE_ALREADY_EXIST:
            return __('Event rule already exists')
        case ZONE_TYPE_ALREADY_EXIST:
            return __('Zone type already exists')
        case NOTIFICATION_TYPE_ALREADY_EXIST:
            return __('Notification type already exists')
        case STATE_TYPE_ALREADY_EXIST:
            return __('State type already exists')
        case EVENT_TYPE_ALREADY_EXIST:
            return __('Event type already exists')
    }

    if (errorType === CUSTOM_ERROR) {
        const mapMessageStartsWith = {
            'Unsupported character': __('Unsupported character'),
        }
        let {message} = details

        for (const key in mapMessageStartsWith) {
            if (message && message.startsWith(key)) {
                message = message.replace(key, mapMessageStartsWith[key])
                break
            }
        }

        return message
    }

    if (typeof details === 'string' || details instanceof String) {
        return details
    }

    warn(`Unknown error type ${errorType}`)

    return __('Unknown server error: %s', errorType)
}

export function messageTitleByErrorType(errorType) {
    switch (errorType) {
        case USER_UNIT_ACCESS_DENIED:
            return __('Access Denied!')
        default:
            return __('Ooops!')
    }
}

const roundedCoolDown = (coolDown) => {
    const mod = coolDown % 60
    if (coolDown <= 60 || mod <= 30) {
        return coolDown
    }

    return coolDown + (60 - mod)
}

const getBlockedMessage = (message, coolDown) => {
    return coolDown
        ? __(
              'Authentication is blocked for %s. %s',
              humanDuration(roundedCoolDown(coolDown)),
              message
          )
        : __('Authentication is blocked. %s', message)
}
