import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import {REPORTS} from 'constants/features'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {createReport} from 'permissions/panels/selection'
import {withSelectionFeature} from 'containers/withFeature'
import {showCreateReportModal} from 'modules/modals/actions'
import {withSelectionHandler} from 'containers/withSelection'

const ConnectedButton = compose(withVisibility(), withSelectionHandler())(Button)

export function CreateReportBaseButton({onClick}) {
    const {isPermitted} = usePermission(createReport)

    return (
        <ConnectedButton Icon={IconPlus} onClick={onClick} isVisible={isPermitted}>
            {__('Add Report')}
        </ConnectedButton>
    )
}

const CreateReportSelectionFeature = withSelectionFeature(REPORTS)(CreateReportBaseButton)

export default function CreateReportButton() {
    const onClick = useActions(showCreateReportModal)

    return <CreateReportSelectionFeature onClick={onClick} />
}
