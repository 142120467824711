import React from 'react'
import {useSelector} from 'react-redux'

import nl2br from 'react-nl2br'

import Button from 'ipmp-react-ui/Button'

import ActionCards, {ActionCard, ActionCardsGroup} from 'ui/ActionCards'

import {
    downloadIQPanelLog,
    engineeringResetPanel,
    rebootPanel,
    remindMasterUserCodeReset,
} from 'permissions/panel/actions'

import {useActions} from 'modules/higherOrder/useActions'
import {selectLogTypes, selectPanelActions} from 'modules/features/store/selectors'
import {
    fetchLogOutsidePm,
    triggerEngineeringReset,
    triggerReboot,
    triggerRemindMasterUserCode,
} from 'modules/devices/list/actions'

import withConfirmation from 'containers/withConfirmation'

import useTrackProcess from 'hooks/useTrackProcess'
import usePermission from 'hooks/usePermission'
import usePanelInfo from 'hooks/pages/usePanelInfo'

import {
    ENGINEERING_RESET_ACTION,
    REBOOT_ACTION,
    MASTER_USER_CODE_RESET_ACTION,
} from 'constants/panelAction'
import {SYSTEM_OUTSIDE_PM_LOG_TYPE} from 'constants/logTypes'

import __ from 'utils/i18n'

const ConfirmationButton = withConfirmation()(Button)

function TriggerRebootButton({panel}) {
    const {onClick} = useActions(
        {
            onClick: () => triggerReboot(panel.id),
        },
        [panel]
    )

    const {process} = useTrackProcess({
        process: panel.panelRebootProcess,
    })

    return (
        <ConfirmationButton
            onClick={onClick}
            disabled={!!process}
            message={nl2br(
                __(
                    'Panel will reboot even if it is in arm state. \n\r' +
                        'Do you want to send reboot command?'
                )
            )}
            title={__('Sending Reboot command')}
        >
            {__('Send')}
        </ConfirmationButton>
    )
}

function TriggerEngineeringResetButton({panel}) {
    const {onClick} = useActions(
        {
            onClick: () => triggerEngineeringReset(panel.id),
        },
        [panel]
    )

    const {process} = useTrackProcess({
        process: panel.panelEngineeringResetProcess,
    })

    return (
        <ConfirmationButton
            onClick={onClick}
            disabled={!!process}
            message={__('Do you want to send engineering reset command?')}
            title={__('Sending Engineering Reset command')}
        >
            {__('Send')}
        </ConfirmationButton>
    )
}

function TriggerMasterUserCodeResetButton({panel}) {
    const {onClick} = useActions(
        {
            onClick: () => triggerRemindMasterUserCode(panel.id),
        },
        [panel]
    )

    const {process} = useTrackProcess({
        process: panel.panelRemindMasterUserCodeProcess,
    })

    return (
        <ConfirmationButton
            onClick={onClick}
            disabled={!!process}
            message={__('Do you want to send remind master user code command?')}
            title={__('Sending Remind Master User Code command')}
        >
            {__('Remind')}
        </ConfirmationButton>
    )
}

function FetchLogOutsidePmButton({panel}) {
    const {onClick} = useActions(
        {
            onClick: () => fetchLogOutsidePm(panel.id),
        },
        [panel]
    )

    const {process} = useTrackProcess({
        process: panel.sysLogFetchOutsidePmProcess,
    })

    return (
        <ConfirmationButton
            onClick={onClick}
            disabled={!!process}
            message={__('Do you really want to download log?')}
            title={__('Download log')}
        >
            {__('Download log')}
        </ConfirmationButton>
    )
}

export default function GeneralInfoTab({panelId}) {
    const {
        isDownloadLogAllowed,
        isTriggerActionRebootAllowed,
        isTriggerActionEngineeringResetAllowed,
        isTriggerActionRemindMasterUserCodeAllowed,
    } = usePermission({
        isDownloadLogAllowed: downloadIQPanelLog,
        isTriggerActionRebootAllowed: rebootPanel,
        isTriggerActionEngineeringResetAllowed: engineeringResetPanel,
        isTriggerActionRemindMasterUserCodeAllowed: remindMasterUserCodeReset,
    })

    const allowedPanelActions = useSelector((state) =>
        selectPanelActions(state, {panelId})
    )

    const {panel} = usePanelInfo()
    const allowedLogTypes = useSelector((state) => selectLogTypes(state, {panelId}))

    const isAllowedReboot =
        isTriggerActionRebootAllowed && allowedPanelActions.includes(REBOOT_ACTION)

    const isAllowedEngineeringReset =
        isTriggerActionEngineeringResetAllowed &&
        allowedPanelActions.includes(ENGINEERING_RESET_ACTION)

    const isAllowedRemindMasterUserCode =
        isTriggerActionRemindMasterUserCodeAllowed &&
        allowedPanelActions.includes(MASTER_USER_CODE_RESET_ACTION)

    return (
        <ActionCardsGroup label={__('General Commands')}>
            {isDownloadLogAllowed &&
                allowedLogTypes.includes(SYSTEM_OUTSIDE_PM_LOG_TYPE) && (
                    <ActionCards label={__('Log options')}>
                        <ActionCard label={__('Panel log')}>
                            <FetchLogOutsidePmButton panel={panel} />
                        </ActionCard>
                    </ActionCards>
                )}

            {(isAllowedReboot ||
                isAllowedEngineeringReset ||
                isAllowedRemindMasterUserCode) && (
                <ActionCards label={__('General management')}>
                    {isAllowedReboot && (
                        <ActionCard label={__('Reboot')}>
                            <TriggerRebootButton panel={panel} />
                        </ActionCard>
                    )}
                    {isAllowedEngineeringReset && (
                        <ActionCard label={__('Engineering reset')}>
                            <TriggerEngineeringResetButton panel={panel} />
                        </ActionCard>
                    )}
                    {isAllowedRemindMasterUserCode && (
                        <ActionCard label={__('Remind master user code')}>
                            <TriggerMasterUserCodeResetButton panel={panel} />
                        </ActionCard>
                    )}
                </ActionCards>
            )}
        </ActionCardsGroup>
    )
}
