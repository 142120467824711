import React from 'react'

import path from 'utils/path'

import InstallersPage from './InstallersPage'

export default class {
    path = path('installers')

    element = (<InstallersPage />)
}
