import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ipmp-react-ui/Definition'
import DateTime from 'ipmp-react-ui/DateTime'

import {__} from 'utils/i18n'

export default class StateReportCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            isFinished: PropTypes.bool,
            isActive: PropTypes.bool,
            next: PropTypes.instanceOf(Date),
        }),
    }

    render() {
        const {
            row: {isFinished, isActive, next},
        } = this.props

        switch (true) {
            case isFinished:
                return <Definition className="empty" title={__('Finished')} />
            case !isActive:
                return <Definition className="empty" title={__('Stopped')} />
            default:
                return <DateTime datetime={next} />
        }
    }
}
