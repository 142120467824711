import React from 'react'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import {__} from 'utils/i18n'
import usePanel from 'hooks/pages/usePanel'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import useConfirmationModal from 'hooks/useConfirmationModal'
import {remove as removePermission} from 'permissions/panel/actions'
import {NO_PLAN_ID} from 'constants/billingPlan'
import {useActions} from 'modules/higherOrder/useActions'
import {showAlert} from 'modules/modals/actions'

const MenuItemVisibility = withVisibility()(MenuItem)

export default function RemovePanelItem() {
    const {panel, remove} = usePanel()
    const {isPermitted} = usePermission(removePermission)
    const [showAlertModal] = useActions([showAlert])

    const billingPlan = panel?.billing?.planId
    const isRemovable = billingPlan === NO_PLAN_ID || billingPlan === undefined

    const {onClick} = isRemovable
        ? useConfirmationModal(
              ({remove}) => [remove],
              () => ({
                  title: __('Removing panel'),
                  message: __('Do you really want to remove selected panel?'),
                  positive: __('Remove'),
              }),
              {remove}
          )
        : {
              onClick: () =>
                  showAlertModal(
                      __('Warning'),
                      __('Panel has future billing plan and cannot be removed.')
                  ),
          }

    return (
        <MenuItemVisibility
            onClick={onClick}
            Icon={IconRemove}
            isVisible={isPermitted}
            children={__('Remove')}
        />
    )
}
