import * as PanelApi from 'api/panel/info'
import * as PanelUserApi from 'api/panel/panelUser'
import * as ConfigurationApi from 'api/panel/configuration'
import * as Plink from 'api/panel/plink'
import * as PBilling from 'api/panel/billing'
import * as PanelsApi from 'api/panels'
import * as RriApi from 'api/remoteInspection'
import * as ReportsApi from 'api/reports'
import * as GroupApi from 'api/group'
import * as GroupsApi from 'api/groups'
import * as UserApi from 'api/user'
import * as DevicesApi from 'api/devices'
import * as CentralStationApi from 'api/centralStations'
import * as RemoteInspectionsApi from 'api/remoteInspection'
import * as ProfileApi from 'api/profile'
import * as RolesApi from 'api/roles'
import * as AuthApi from 'api/auth'
import * as SystemSettingsApi from 'api/system/settings'
import * as InteractiveUsersApi from 'api/interactiveUsers'
import * as InstallerApi from 'api/installers'
import * as ZoneTypeApi from 'api/zoneTypes'
import * as EventRuleApi from 'api/eventRules'
import * as NotificationTypeApi from 'api/notificationTypes'
import * as EventTypeApi from 'api/eventTypes'
import * as StateTypeApi from 'api/stateTypes'
import * as HadApi from 'api/had'
import * as EventApi from 'api/events'

import {
    PROCESS_TYPE_SET_LABEL,
    PROCESS_TYPE_SET_ICON,
    PROCESS_TYPE_PMAXZONEADD,
    PROCESS_TYPE_NEOACTIVATION,
    PROCESS_TYPE_CHANGE_INSTALLER_PIN,
    PROCESS_TYPE_PMAXASSIGNPARTITIONS,
    PROCESS_TYPE_SET_PANEL_USER_CODE,
    PROCESS_TYPE_EVENT_INITIATE,
    PROCESS_TYPE_CONNECT_DLS,
    PROCESS_TYPE_USER_ACTIVATION,
    PROCESS_TYPE_DISABLE_PANEL_USER,
    PROCESS_TYPE_SET_PANEL_USER_LASTNAME,
    PROCESS_TYPE_SET_PANEL_USER_EXPIRATION_DATE,
    PROCESS_TYPE_BILLING_ACTIVATE,
} from 'constants/processTypes'

import createFormHandler, {
    createFormHandlerGeneratedProcess,
    createFormHandlerHadCommand,
} from 'modules/forms/helpers/createFormHandler'

export const markForService = createFormHandler(
    'markForService',
    (ids, comment, userId = null) => PanelsApi.markForService(ids, comment, userId),
    (ids, comment, userId = null, user = null) => ({ids, comment, userId, user})
)

export const reassignService = createFormHandler(
    'reassignService',
    (ids, comment, userId = null) => PanelsApi.reassign(ids, comment, userId),
    (ids, comment, userId = null, user = null) => ({ids, comment, userId, user})
)

export const changeGroup = createFormHandler(
    'changeGroup',
    (ids, groupId) => PanelsApi.changeGroup(ids, groupId),
    (ids, groupId) => ({ids, groupId})
)

export const addPanel = createFormHandler('addPanel', PanelApi.createNew)

export const editPanelInfo = createFormHandler(
    'editPanelInfo',
    PanelApi.updateData,
    (panelId, data) => ({id: panelId, data})
)

export const setUserCode = createFormHandlerGeneratedProcess(
    'setUserPin',
    PanelUserApi.setUserPin,
    ({id, code, panelId}) => ({id, code, panelId}),
    PROCESS_TYPE_SET_PANEL_USER_CODE
)

export const setPanelUserLabel = createFormHandlerGeneratedProcess(
    'setPanelUserLabel',
    PanelUserApi.setPanelUserLabel,
    ({panelId, panelUserId, label}) => ({panelId, panelUserId, label}),
    PROCESS_TYPE_SET_LABEL
)

export const setPanelUserPartitions = createFormHandlerGeneratedProcess(
    'setUserPartitions',
    PanelUserApi.setPanelUserPartitions,
    ({panelId, panelUserId, partitions}) => ({panelId, panelUserId, partitions}),
    PROCESS_TYPE_PMAXASSIGNPARTITIONS
)

export const setPanelUserLastName = createFormHandlerGeneratedProcess(
    'setPanelUserLastName',
    PanelUserApi.setPanelUserLastName,
    ({panelId, panelUserId, lastName}) => ({panelId, panelUserId, lastName}),
    PROCESS_TYPE_SET_PANEL_USER_LASTNAME
)

export const setPanelUserExpirationDate = createFormHandlerGeneratedProcess(
    'setPanelUserExpirationDate',
    PanelUserApi.setPanelUserExpirationDate,
    ({panelId, panelUserId, expirationDate}) => ({panelId, panelUserId, expirationDate}),
    PROCESS_TYPE_SET_PANEL_USER_EXPIRATION_DATE
)

export const disablePanelUser = createFormHandlerGeneratedProcess(
    'setUserPin',
    PanelUserApi.disablePanelUser,
    (id, panelId) => ({id, panelId}),
    PROCESS_TYPE_DISABLE_PANEL_USER
)

export const editPanelCustomerInfo = createFormHandler(
    'editPanelCustomerInfo',
    PanelApi.updateInfo,
    (panelId, data) => ({panelId, data})
)

export const createBasicConfiguration = createFormHandler(
    'createBasicConfiguration',
    ConfigurationApi.makeBasic,
    (panelId) => ({panelId})
)

export const activatePanel = createFormHandlerGeneratedProcess(
    'activatePanel',
    PanelApi.activatePanel,
    (panelId, installerCode) => ({
        panelId,
        installerCode,
    }),
    PROCESS_TYPE_NEOACTIVATION
)

export const activateMasterGUIPanel = createFormHandlerGeneratedProcess(
    'activateMasterGUIPanel',
    PanelApi.activateMasterGUIPanel,
    (panelId, masterUserCode) => ({
        panelId,
        masterUserCode,
    }),
    PROCESS_TYPE_USER_ACTIVATION
)

export const editGroupCsLinks = createFormHandler(
    'editGroupCsLinks',
    GroupApi.persistCentralStationLinks,
    (groupId, centralStationId, profiles) => ({groupId, centralStationId, profiles})
)

export const addGroup = createFormHandler('addGroup', GroupsApi.add)

export const editGroup = createFormHandler('editGroup', GroupsApi.edit, (id, data) => ({
    id,
    ...data,
}))

export const editEventsProfileEventProfiles = createFormHandler(
    'editEventsProfileEventProfiles',
    GroupApi.updateGroupNotificationsEventProfiles,
    (id, data) => ({
        id,
        ...data,
    })
)

export const editEventsProfileEndUser = createFormHandler(
    'editEventsProfileEndUser',
    GroupApi.updateGroupNotificationsEndUser,
    (id, data) => ({
        id,
        ...data,
    })
)

export const resolveFaults = createFormHandler(
    'resolveFaults',
    PanelsApi.resolveFaults,
    (faultIds) => ({faultIds})
)

export const suspendFaults = createFormHandler(
    'suspendFaults',
    PanelsApi.suspendFaults,
    (faultIds) => ({faultIds})
)

export const resumeFaults = createFormHandler(
    'resumeFaults',
    PanelsApi.resumeFaults,
    (faultIds) => ({faultIds})
)

export const scheduleRri = createFormHandler(
    'scheduleRri',
    (ids, date, repetition) => {
        if (date) {
            return RriApi.scheduleInspection(ids, date, repetition)
        }

        return RriApi.cancelInspection(ids)
    },
    (ids, date, repetition) => ({ids, date, repetition})
)

export const addCentralStation = createFormHandler(
    'addCentralStation',
    CentralStationApi.create
)

export const editCentralStation = createFormHandler(
    'editCentralStation',
    CentralStationApi.edit,
    (data) => ({data})
)

export const editRemoteInspectionValues = createFormHandler(
    'editRemoteInspectionValues',
    RemoteInspectionsApi.setValues,
    (data) => ({...data})
)

export const createReport = createFormHandler(
    'createReport',
    ReportsApi.createReport,
    (data) => ({data})
)

export const addUser = createFormHandler('addUser', UserApi.add)

export const editUser = createFormHandler('editUser', UserApi.edit, (id, data) => {
    return {
        id,
        ...data,
    }
})

export const addDevice = createFormHandlerGeneratedProcess(
    'addDevice',
    DevicesApi.addDevice,
    (panelId, zoneId, deviceId, partitions, userId) => {
        return {
            panelId,
            zoneId,
            deviceId,
            partitions,
            userId,
        }
    },
    PROCESS_TYPE_PMAXZONEADD
)

export const addSrfDevice = createFormHandlerGeneratedProcess(
    'addDevice',
    DevicesApi.addSrfDevice,
    (panelId, deviceId, partitions, userId, deviceSubtype) => {
        return {
            panelId,
            deviceId,
            partitions,
            userId,
            deviceSubtype,
        }
    },
    PROCESS_TYPE_PMAXZONEADD
)

export const addRtspCamera = createFormHandlerGeneratedProcess(
    'addRtspCamera',
    DevicesApi.addRtspCamera,
    ({panelId, serial, rtsp_label, stream, device_number, rtsp_partitions}) => {
        return {
            panelId,
            serial,
            rtsp_label,
            stream,
            device_number,
            rtsp_partitions,
        }
    },
    PROCESS_TYPE_PMAXZONEADD
)

export const addProvisionCamera = createFormHandlerGeneratedProcess(
    'addProvisionCamera',
    DevicesApi.addProvisionCamera,
    ({
        panelId,
        panelSerial,
        label,
        user,
        device_number,
        password,
        serial_number,
        partitions,
    }) => {
        return {
            panelId,
            panelSerial,
            label,
            user,
            password,
            device_number,
            serial_number,
            partitions,
        }
    },
    PROCESS_TYPE_PMAXZONEADD
)

export const editProfile = createFormHandler(
    'editProfile',
    ProfileApi.editProfile,
    (data) => ({...data})
)

export const changePassword = createFormHandler(
    'changePassword',
    ProfileApi.changePassword
)

export const changeOTPPassword = createFormHandler(
    'changeOTPPassword',
    ProfileApi.changeOTPPassword,
    (content) => content
)

export const rebootPlink = createFormHandler('rebootPlink', Plink.rebootPlink)

export const enablePlinkLogging = createFormHandler(
    'enablePlinkLogging',
    Plink.enablePlinkLogging
)

export const disablePlinkLogging = createFormHandler(
    'disablePlinkLogging',
    Plink.disablePlinkLogging
)

export const addRole = createFormHandler('addRole', RolesApi.add)

export const editRole = createFormHandler('editRole', RolesApi.edit)

export const removeRole = createFormHandler('removeRole', RolesApi.remove)

export const editRolePermissions = createFormHandler(
    'editRolePermissions',
    RolesApi.editPermissions
)

export const scheduleInspectionByCsvFile = createFormHandler(
    'scheduleInspectionByCsvFile',
    RemoteInspectionsApi.scheduleInspectionByCsv,
    (content) => content
)

export const renameDevice = createFormHandlerGeneratedProcess(
    'renameDevice',
    DevicesApi.renameEntity,
    (panelId, entityClass, entityId, name) => ({
        panelId,
        entityClass,
        entityId,
        name,
    }),
    PROCESS_TYPE_SET_LABEL
)

export const renamePartition = createFormHandlerGeneratedProcess(
    'renamePartition',
    DevicesApi.renamePartition,
    (panelId, partitionId, name) => ({
        panelId,
        partitionId,
        name,
    }),
    PROCESS_TYPE_SET_LABEL
)

export const remindPassword = createFormHandler('remindPassword', ({email}) =>
    AuthApi.remindPassword(email)
)

export const setSystemSettings = {
    CellularConnected: createFormHandler('setSystemSettings.CellularConnected', (data) =>
        SystemSettingsApi.setCellularConnected(data)
    ),
    BroadbandConnected: createFormHandler(
        'setSystemSettings.BroadbandConnected',
        (data) => SystemSettingsApi.setBroadbandConnected(data)
    ),
    CommonConnected: createFormHandler('setSystemSettings.CommonConnected', (data) =>
        SystemSettingsApi.setCommonConnected(data)
    ),
    Supervision: createFormHandler('setSystemSettings.Supervision', (data) =>
        SystemSettingsApi.setSupervision(data)
    ),
    RemoteInspection: createFormHandler('setSystemSettings.RemoteInspection', (data) =>
        SystemSettingsApi.setRemoteInspection(data)
    ),
    InteractiveSession: createFormHandler(
        'setSystemSettings.InteractiveSession',
        (data) => SystemSettingsApi.setInteractiveSession(data)
    ),
    UserNotification: createFormHandler('setSystemSettings.UserNotification', (data) =>
        SystemSettingsApi.setUserNotifications(data)
    ),
    Advertisement: createFormHandler('setSystemSettings.Advertisement', (data) =>
        SystemSettingsApi.setAdvertisement(data)
    ),
    MessageBroker: createFormHandler('setSystemSettings.saveMessageBroker', (data) =>
        SystemSettingsApi.saveMessageBroker(data)
    ),
    AuthorizationSettings: createFormHandler(
        'setSystemSettings.saveAuthorizationSettings',
        (data) => SystemSettingsApi.setAuthorizationSettings(data)
    ),
    Rotation: createFormHandler('setSystemSettings.Rotation', (data) =>
        SystemSettingsApi.setRotation(data)
    ),
    MFAGeneral: createFormHandler('setSystemSettings.MFAGeneral', (data) =>
        SystemSettingsApi.setMfa(data)
    ),
    GoogleSettings: createFormHandler('setSystemSettings.GoogleSettings', (data) =>
        SystemSettingsApi.setGoogleSettings(data)
    ),
    PgmActivationTimeSlot: createFormHandler(
        'setSystemSettings.PgmActivationTimeSlot',
        (data) => SystemSettingsApi.setPgmActivationTimeSlot(data)
    ),
    SelfRegistration: createFormHandler('setSystemSettings.SelfRegistration', (data) =>
        SystemSettingsApi.setSelfRegistration(data)
    ),
}

export const assignUserToPanel = createFormHandler(
    'assignUserToPanel',
    InteractiveUsersApi.assignUserToPanel
)

export const registerInteractiveUser = createFormHandler(
    'registerInteractiveUser',
    InteractiveUsersApi.register
)

export const assignInstallerToPanel = createFormHandler(
    'assignInstallerToPanel',
    InstallerApi.assignInstallerToPanel
)

export const registerInstaller = createFormHandler(
    'registerInstaller',
    InstallerApi.registerInstaller
)

export const changeInstallerCode = createFormHandlerGeneratedProcess(
    'changeInstallerCode',
    InstallerApi.changeInstallerPin,
    ({type, pin, panelId}) => ({type, pin, panelId}),
    PROCESS_TYPE_CHANGE_INSTALLER_PIN
)

export const addZoneType = createFormHandler('addZoneType', ZoneTypeApi.add)

export const editZoneType = createFormHandler('editZoneType', ZoneTypeApi.edit)

export const addNotificationType = createFormHandler(
    'addNotificationType',
    NotificationTypeApi.add
)

export const editNotificationType = createFormHandler(
    'editNotificationType',
    NotificationTypeApi.edit
)

export const addStateType = createFormHandler('addStateType', StateTypeApi.add)

export const editStateType = createFormHandler('editStateType', StateTypeApi.edit)

export const addEventType = createFormHandler('addEventType', EventTypeApi.add)
export const editEventType = createFormHandler('editEventType', EventTypeApi.edit)

export const addEventRule = createFormHandler('addEventRule', EventRuleApi.add)

export const editEventRule = createFormHandler('editEventRule', EventRuleApi.edit)

export const submitHadDeviceControlForm = createFormHandlerHadCommand(
    'submitHadDeviceControlForm',
    HadApi.setHadCommand,
    ({commands, params}) => ({commands, params})
)

export const eventInitiate = createFormHandlerGeneratedProcess(
    'setEmergencyPartitions',
    EventApi.initiateEvent,
    ({panelId, eventType, partitions}) => ({panelId, eventType, partitions}),
    PROCESS_TYPE_EVENT_INITIATE
)

export const editHadIcon = createFormHandlerGeneratedProcess(
    'editHadIcon',
    HadApi.setIcon,
    ({panelId, hadId, deviceType, icon}) => ({panelId, hadId, deviceType, icon}),
    PROCESS_TYPE_SET_ICON
)

export const editHadLabel = createFormHandlerGeneratedProcess(
    'editHadLabel',
    HadApi.setLabel,
    ({panelId, hadId, deviceType, label}) => ({panelId, hadId, deviceType, label}),
    PROCESS_TYPE_SET_LABEL
)

export const dlsConnectSmartCommunicator = createFormHandlerGeneratedProcess(
    'dlsConnectSmartCommunicator',
    DevicesApi.dlsConnectSmartCommunicator,
    (
        panelId,
        host,
        port,
        password,
        isEncrypted,
        timeout,
        panelType,
        connectionSpeed
    ) => ({
        panelId,
        host,
        port,
        password,
        isEncrypted,
        timeout,
        panelType,
        connectionSpeed,
    }),
    PROCESS_TYPE_CONNECT_DLS
)

export const dlsConnectDsc = createFormHandlerGeneratedProcess(
    'dlsConnectDsc',
    DevicesApi.dlsConnectDsc,
    (panelId, host, port, networkProtocol, applicationProtocol) => {
        return {
            panelId,
            host,
            port,
            networkProtocol,
            applicationProtocol,
        }
    },
    PROCESS_TYPE_CONNECT_DLS
)

export const panelBillingActivate = createFormHandlerGeneratedProcess(
    'panelBillingActivate',
    PBilling.activate,
    ({planId, panelId}) => ({planId, panelId}),
    PROCESS_TYPE_BILLING_ACTIVATE
)

export const panelBillingDeactivate = createFormHandlerGeneratedProcess(
    'panelBillingDeactivate',
    PBilling.deactivate,
    ({panelId}) => ({panelId}),
    PROCESS_TYPE_BILLING_ACTIVATE
)

export const convertInstaller = createFormHandler(
    'convertInstaller',
    InstallerApi.convert
)
