import React from 'react'
import {useSelector} from 'react-redux'
import {createSelector} from 'reselect'

import Ava from 'ipmp-react-ui/Ava'
import isRtl from 'ipmp-react-ui/_utils/isRtl'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconPrivacy} from 'icons/shield.svg'
import {ReactComponent as IconHelp} from 'ipmp-react-ui/icons/help.svg'
import {ReactComponent as IconProfile} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as IconLogout} from 'ipmp-react-ui/icons/logout.svg'
import {ReactComponent as IconPassword} from 'ipmp-react-ui/icons/password.svg'
import {ReactComponent as IconSettings} from 'ipmp-react-ui/icons/settings.svg'
import DropDown, {ALIGN_LEFT, ALIGN_RIGHT, ALIGN_TOP} from 'ipmp-react-ui/DropDown'

import {logout} from 'modules/auth/sign/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {
    showChangePasswordModal,
    showEditProfileInfoModal,
    showEditSettingsModal,
} from 'modules/modals/actions'

import {__} from 'utils/i18n'
import {privacyPolicyLink} from 'constants/externalLinks'

const userInfoSelector = createSelector(
    (state) => state.auth.sign.user,
    (state) => state.system.settings.serverVersion,
    (user, serverVersion) => ({user, serverVersion})
)

export default function UserInfo() {
    const handleHelpClick = () => {
        const {version} = serverVersion || {}
        const [major, minor] = (version || '').split('.')

        window.open(`https://help.tycomonitor.com/${major}.${minor}/`, '_blank')
    }

    const handlePrivacyPolicyClick = () => {
        window.open(privacyPolicyLink, '_blank')
    }

    const {user, serverVersion} = useSelector(userInfoSelector)

    const actions = useActions({
        logout,
        showEditSettingsModal,
        showChangePasswordModal,
        showEditProfileInfoModal,
    })

    return (
        <DropDown
            trigger={<Ava name={user.name} id={user.id} />}
            align={(isRtl() ? ALIGN_LEFT : ALIGN_RIGHT) | ALIGN_TOP}
            className="userInfo"
            toggled
        >
            <div className="card">
                <div className="userInfo-user">
                    <Ava className="userInfo-ava" large name={user.name} id={user.id} />

                    <div className="userInfo-main-info">
                        <strong className="userInfo-name">{user.name}</strong>
                        {user.roleName && (
                            <span className="userInfo-role">{user.roleName}</span>
                        )}
                        {user.dealerId && (
                            <span className="userInfo-details">
                                {__('Dealer id')}: {user.dealerId}
                            </span>
                        )}
                        {user.email && (
                            <span className="userInfo-details">
                                <OverflowTextTooltip tooltip={user.email}>
                                    {user.email}
                                </OverflowTextTooltip>
                            </span>
                        )}
                        {user.phone && (
                            <span className="userInfo-details">{user.phone}</span>
                        )}
                    </div>
                </div>

                <Menu className="userInfo-menu">
                    <MenuItem Icon={IconSettings} onClick={actions.showEditSettingsModal}>
                        {__('Settings')}
                    </MenuItem>
                    <MenuItem
                        Icon={IconProfile}
                        onClick={actions.showEditProfileInfoModal}
                    >
                        {__('Edit Profile')}
                    </MenuItem>
                    <MenuItem
                        Icon={IconPassword}
                        onClick={actions.showChangePasswordModal}
                    >
                        {__('Change Password')}
                    </MenuItem>
                    <MenuItem Icon={IconPrivacy} onClick={handlePrivacyPolicyClick}>
                        {__('Privacy Policy')}
                    </MenuItem>
                    <MenuItem Icon={IconHelp} onClick={handleHelpClick}>
                        {__('Help')}
                    </MenuItem>
                    <MenuItem Icon={IconLogout} onClick={actions.logout}>
                        {__('Logout')}
                    </MenuItem>
                </Menu>
            </div>
        </DropDown>
    )
}
