import React from 'react'
import head from 'lodash-es/head'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {__, __n} from 'utils/i18n'
import useForm from 'hooks/useForm'
import decorate from 'utils/decorate'
import ModalCardForm from 'ui/ModalCardForm'
import {removeRole} from 'modules/forms/handlers'

const renderMessage = (unRemovableRoles, roles) => {
    if (unRemovableRoles.length === 0) {
        return __n(
            'Do you really want to remove %s selected role?',
            'Do you really want to remove %s selected roles?',
            roles.length
        )
    }

    if (unRemovableRoles.length > 1) {
        return decorate(
            __(
                'Roles %s has related users or roles and cannot be removed. Remove related users and roles before.'
            ),
            unRemovableRoles.map(({name}) => name).join(', ')
        )
    }

    const role = head(unRemovableRoles)

    if (!role.removable) {
        if (role.isSelfRegistrationDefaultRole) {
            return decorate(
                __(
                    'Role %s cannot be deleted, because it is used as default in Resolve\\Self-Registration Settings.'
                ),
                role.name
            )
        }

        return decorate(
            __('Role %s cannot be deleted, because it is not allowed.'),
            role.name
        )
    } else {
        return decorate(
            __(
                'Role %s cannot be deleted, because it has %s child users and %s child roles. Remove them before delete this user'
            ),
            role.name,
            role.relatedUsers,
            role.relatedRoles
        )
    }
}

export default function RemoveRole({hide, roleIds}) {
    const {form, handle, isLoading} = useForm(removeRole)
    const roles = useSelector(
        ({
            roles: {
                store: {byIds},
            },
        }) => Object.values(byIds).filter(({id}) => roleIds.includes(id))
    )

    const handler = () => handle(roleIds)
    const unRemovableRoles = roles.filter(({isRemovable}) => !isRemovable)

    return (
        <ModalCardForm
            {...form}
            showDismiss
            hide={hide}
            onSubmit={handler}
            isLoading={isLoading}
            editable={!unRemovableRoles.length}
            header={roles.length > 1 ? __('Remove roles') : __('Remove role')}
        >
            {renderMessage(unRemovableRoles, roles)}
        </ModalCardForm>
    )
}
RemoveRole.propTypes = {
    hide: PropTypes.func.isRequired,
    roleIds: PropTypes.arrayOf(PropTypes.number),
}
