import PanelsRoute from './Panels/PanelsRoute'
import PanelRoute from './Panel/PanelRoute'
import GroupsRoute from './Groups/GroupsRoute'
import GroupRoute from './Group/GroupRoute'
import RemoteInspectionRoute from './RemoteInspection/RemoteInspectionRoute'
import EventsRoute from './Events/EventsRoute'
import PageNotFound from './PageNotFound'
import ReportsRoute from './Reports/ReportsRoute'
import ActionLog from './ActionLog/ActionLogRoute'
import ProcessesRoute from './Processes/ProcessesRoute'
import UsersRoute from './Users/UsersRoute'
import RolesRoute from './Roles/RolesRoute'
import CentralStationsRoute from './CentralStations/CentralStationsRoute'
import CentralStationRoute from './CentralStation/CentralStationRoute'
import Firmware from './Firmware/FirmwareRoute'
import FirmwareUpgrade from './Firmware/Upgrade/FirmwareUpgradeRoute'
import FirmwarePatchTagUpdateRoute from './Firmware/PatchTagUpdate/FirmwarePatchTagUpdateRoute'
import BasicConfigurationsRoute from './BasicConfigurations/BasicConfigurationsRoute'
import BasicConfigurationRoute from './BasicConfiguration/BasicConfigurationRoute'
import AccountsRoute from 'pages/InteractiveUsers/AccountsRoute'
import InstallersRoute from 'pages/Installers/InstallersRoute'
import DashboardRoute from './Dashboard/DashboardRoute'
import PushBasicConfigurationRoute from './PushBasicConfiguration/PushBasicConfigurationRoute'
import BatchesRoute from './Batches/BatchesRoute'
import SettingsRoute from './Settings/SettingsRoute'
import BatchesRunnersRoute from './Batches/Runners/BatchesRunnersRoute'

export default [
    new PanelsRoute(),
    new PanelRoute(),
    new GroupsRoute(),
    new GroupRoute(),
    new RemoteInspectionRoute(),
    new ReportsRoute(),
    new ActionLog(),
    new ProcessesRoute(),
    new UsersRoute(),
    new RolesRoute(),
    new EventsRoute(),
    new CentralStationsRoute(),
    new CentralStationRoute(),
    new FirmwarePatchTagUpdateRoute(),
    new FirmwareUpgrade(),
    new Firmware(),
    new BasicConfigurationsRoute(),
    new BasicConfigurationRoute(),
    new AccountsRoute(),
    new InstallersRoute(),
    new DashboardRoute(),
    new PushBasicConfigurationRoute(),
    new BatchesRoute(),
    new BatchesRunnersRoute(),
    new SettingsRoute(),
    new PageNotFound(), // Must be last in list
]
