import createRowsResponseParser from 'api/base/createRowsResponseParser'
import {get, post} from 'api/http'
import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'
import mapSuggests from 'api/base/mapSuggests'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {mapReports, keysMap} from './reports.map'

export function fetch({start = 0, perPage: count = 10, query, filters}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/units_report/getallreports', {start, count, query, filter}).then(
        createRowsResponseParser(mapReports)
    )
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/units_report/suggestreports', {suggest, start, count}).then(
        mapSuggests(fields, keysMap)
    )
}

export function remove(ids) {
    return post('/units_report_remove/remove', {ret_id: ids})
}

export function stop(ids) {
    return post('/units_report_stop/stop', {ret_id: ids})
}

export function review(reportId) {
    return post('/units_report/review', {ret_id: [reportId]})
}

export function buildDownloadReportHref(reportId, exportType) {
    return `/api/units_report/download/id/${reportId}/type/${exportType}`
}

export function buildDownloadReportResultHref(unitId, reportResultId, exportType) {
    return `/api/unit_report_results/download/unt_id/${unitId}/id/${reportResultId}/type/${exportType}`
}

export function fetchReportEmails(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallemails', {prefix}).then(({rows, count}) =>
        rows.map(({rte_id, rte_email: email}) => email)
    )
    /* eslint-enable camelcase */
}

export function fetchReportFtpServers(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallftps', {prefix}).then(
        createRowsResponseParser(({rtf_id, ftp_string}) => ({
            id: parseInt(rtf_id),
            name: ftp_string,
        }))
    )
    /* eslint-enable camelcase */
}

export function fetchReportContentOptions(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallcontentoptions', {prefix}).then(
        createRowsResponseParser(({rco_id, rco_name}) => ({
            id: parseInt(rco_id),
            name: rco_name,
        }))
    )
    /* eslint-enable camelcase */
}

export function fetchReportPmaxIndexes(prefix) {
    /* eslint-disable camelcase */
    return get('/units_report/getallpmaxconfigoptions', {prefix}).then(
        createRowsResponseParser(({eii_id, eii_index_description}) => ({
            id: parseInt(eii_id),
            name: eii_index_description,
        }))
    )
    /* eslint-enable camelcase */
}

export function createReport(data) {
    const mapPanels = ({panelIds, groupId}) => {
        if (Array.isArray(panelIds)) {
            return {unt_id: panelIds, num_rows: panelIds.length}
        }

        return {utg_id: groupId}
    }

    const panels = mapPanels(data)
    delete data.panelIds
    delete data.groupId

    return post('/units_report_create/create', {
        ...data,
        ...panels,
    }).catch((e) => mapValidationErrors(e, keysMap))
}
