import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import DragNDropList, {DragNDropItem} from 'ipmp-react-ui/DragNDropList'
import {ReactComponent as IconTrailing} from 'ipmp-react-ui/icons/sandwich.svg'
import {ScrollView} from 'ipmp-react-ui/Layout'

import {
    DEFAULT_MENU_ORDER_MANUAL,
    getSortedMenu,
    MENU_ORDER_TYPE_MANUAL,
} from 'constants/mainMenu'
import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import * as permissions from 'permissions/navigation'

export default function OrderPreview({orderType, isLabelDisabled, setMenu}) {
    const settings = useSelector(({settings}) => settings)
    const {permissionsPage} = usePermission({permissionsPage: permissions})

    const actualMenuOrdering =
        settings?.menuOrderManual &&
        Object.keys(settings?.menuOrderManual).length ===
            Object.keys(DEFAULT_MENU_ORDER_MANUAL).length
            ? getSortedMenu(Object.keys(settings?.menuOrderManual))
            : getSortedMenu(Object.keys(DEFAULT_MENU_ORDER_MANUAL))

    const [sortedMenu, setSortedMenu] = useState(actualMenuOrdering)
    const [orderedItems, setOrder] = useState([])

    const onChangeOrder = (orderedMenus) => {
        orderedMenus = [
            ...orderedMenus,
            ...Object.keys(actualMenuOrdering).filter(
                (key) => !orderedMenus.includes(key)
            ),
        ]

        setOrder(orderedMenus)
        setMenu(orderedMenus)
        setSortedMenu(getSortedMenu(orderedMenus))
    }

    useEffect(() => {
        sortedMenu && setMenu(Object.keys(sortedMenu))
    }, [])

    const DragNDropItems = Object.keys(sortedMenu).reduce((items, key) => {
        const {icon, name, isPermitted} = sortedMenu[key]

        if (isPermitted(permissionsPage)) {
            items.push(
                <DragNDropItem
                    key={key}
                    id={key}
                    title={name()}
                    index={orderedItems.indexOf(key)}
                    LeadingIcon={icon}
                    TrailingIcon={IconTrailing}
                />
            )
        }

        return items
    }, [])

    return (
        <>
            <h3 className="form-section-header">{__('Preview')}</h3>

            <ScrollView>
                <DragNDropList
                    order={[]}
                    onChange={onChangeOrder}
                    isDisabled={orderType !== MENU_ORDER_TYPE_MANUAL}
                    isLabelDisabled={isLabelDisabled}
                >
                    {DragNDropItems}
                </DragNDropList>
            </ScrollView>
        </>
    )
}

OrderPreview.propTypes = {
    orderType: PropTypes.string.isRequired,
    isLabelDisabled: PropTypes.bool.isRequired,
    setMenu: PropTypes.func.isRequired,
}
