import React from 'react'
import {compose} from 'redux'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import usePermission from 'hooks/usePermission'
import useSelection from 'hooks/useSelection'
import useConfirmationModal from 'hooks/useConfirmationModal'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import {withSelectionFilter} from 'containers/withFeature'
import {remove as isVisible} from 'permissions/panels/selection'
import {remove} from 'modules/panels/list/actions'
import {deselectAll} from 'modules/selection/actions'
import {selectSelectedBillingFilter} from 'modules/panels/store/selectors'
import {__, __n} from 'utils/i18n'

const ConnectedMenuItem = compose(withVisibility(), withSelectionHandler())(MenuItem)

export function RemovePanelsBase({onClick}) {
    const {isPermitted} = usePermission(isVisible)

    return (
        <ConnectedMenuItem
            Icon={IconRemove}
            onClick={() =>
                onClick({
                    unavailableFeatureModalTitle: () => __('Removing panels'),
                    partiallyUnavailableFeatureModalTitle: (panels) =>
                        __n(
                            '%d panel have future billing plan and cannot be removed. Do you want to continue removing without such panel?',
                            '%d panels have future billing plan and cannot be removed. Do you want to continue removing without such panels?',
                            panels
                        ),
                    unavailableFeatureModalText: () =>
                        __(
                            'Selected panels have future billing plan and cannot be removed'
                        ),
                })
            }
            isVisible={isPermitted}
            children={__('Remove')}
        />
    )
}

const RemovePanelsSelectionFeature = withSelectionFilter(selectSelectedBillingFilter)(
    RemovePanelsBase
)

export default function RemovePanelsItem() {
    const {selection} = useSelection()

    const {onClick} = useConfirmationModal(
        ({selection}, availableIds) => [
            deselectAll(),
            remove(availableIds || selection.toJS()),
        ],
        () => ({
            title: __('Removing panels'),
            message: __('Do you really want to remove selected panels?'),
            positive: __('Remove'),
        }),
        {selection}
    )

    return <RemovePanelsSelectionFeature onClick={onClick} />
}
